import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import { parse } from "date-fns";
import React, { use, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { TimeRangePicker } from "rsuite";
import swal from "sweetalert";
import { makeGetRequest, makePostRequest } from "../../../utils/utils";
// Modal
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
// End
import AddIcon from "@mui/icons-material/Add";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import SwapHorizontalCircleIcon from "@mui/icons-material/SwapHorizontalCircle";
import { Button, Grid } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import moment from "moment";
import picPro from "../../../assets/images/avtarPic.png";
import editwhite from "../../../assets/images/editwhite.svg";

// For Accorddian
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
// End

import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import RoomIcon from "@mui/icons-material/Room";
import SearchIcon from "@mui/icons-material/Search";
import Switch from "@mui/material/Switch";
import Slider from "react-slick";
import picAv from "../../../assets/images/Profile-Picture.jpg";

// for Radio
import FormControl from "@mui/material/FormControl";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
// End

import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import CompressIcon from "@mui/icons-material/Compress";
import publishIco from "../../../assets/images/publish.png";

import CheckIcon from "@mui/icons-material/Check";
import EditIcon from "@mui/icons-material/Edit";

import EventBusyIcon from '@mui/icons-material/EventBusy';

import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

const label = { inputProps: { "aria-label": "Switch demo" } };


 // For attemTab
 function CustomTabPanel(props) {
   const { children, value, index, ...other } = props;
 
   return (
     <div
       role="tabpanel"
       hidden={value !== index}
       id={`simple-tabpanel-${index}`}
       aria-labelledby={`simple-tab-${index}`}
       {...other}
     >
       {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
     </div>
   );
 }
 
 CustomTabPanel.propTypes = {
   children: PropTypes.node,
   index: PropTypes.number.isRequired,
   value: PropTypes.number.isRequired,
 };
 
 function a11yProps(index) {
   return {
     id: `simple-tab-${index}`,
     'aria-controls': `simple-tabpanel-${index}`,
   };
 }
//  End


function SchedulPreview() {
  const navigate = useNavigate();
  const [changeDetected, setChangeDetected] = useState(false);


  // For attemTab
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  // End

  // For Time Picker
  // const [startTimeedit] = React.useState();
  // const [endTimeedit] = React.useState();
  const [assignedempeditobj, setAssignedEmpEditObject] = useState({});
  const [oldShiftTime, setOldShiftTime] = useState();
  // const clickEdi=(obj,shifttime)=> {
  //   setAssignedEmpEditObject(obj);
  //   setOldShiftTime(shifttime);
  //   const [startTimeStr, endTimeStr] = shifttime
  //     ? shifttime.split("-")
  //     : [null, null];

  //       // Parse the start and end times
  //       const startTime = startTimeStr
  //         ? parseTimeString(startTimeStr.trim())
  //         : null;
  //       const endTime = endTimeStr ? parseTimeString(endTimeStr.trim()) : null;

  //       setStartTime(startTime);
  //       setEndTime(endTime);
  //   var element = document.getElementById("EditSftId");
  //   element.classList.toggle("ShowEditSftDiv");
  // }

  // const closeEdit=()=>{
  //   alert("in")
  //   setAssignedEmpEditObject({});
  //   setOldShiftTime();
  //   setStartTime();
  //   setEndTime();
  //   var element = document.getElementById("EditSftId");
  //   element.classList.toggle("ShowEditSftDiv");
  // }
  // End

  // For Modal

  const clickEdi = (obj, shifttime) => {
    setAssignedEmpEditObject(obj);
    setOldShiftTime(shifttime);

    const [startTimeStr, endTimeStr] = shifttime
      ? shifttime.split("-")
      : [null, null];

    setStartTime(startTimeStr ? parseTimeString(startTimeStr.trim()) : null);
    setEndTime(endTimeStr ? parseTimeString(endTimeStr.trim()) : null);

    document.getElementById("EditSftId")?.classList.add("ShowEditSftDiv");
  };

  const [newShiftTime, setNewShiftTime] = useState();
  const updateEmployeeLocalShift = async () => {
    //const newShiftTimeFormatted = `${formatTime(startTimeedit)}-${formatTime(endTimeedit)}`;

    setShowLoader(true);
    // Check if the newShiftTime already exists for the given location and weekday
    const isShiftTimePresent = slotdetailview.some(
      (location) =>
        location.location === assignedempeditobj.location &&
        location.weekdays.some(
          (weekday) =>
            weekday.weekday === assignedempeditobj.weekday &&
            weekday.shifts.some((shift) => shift.shifttime === newShiftTime)
        )
    );

    if (isShiftTimePresent) {
      await swal(
        "Error",
        `The shift time ${newShiftTime} already exists for ${assignedempeditobj.weekday} at ${assignedempeditobj.location}.`,
        "error"
      );
      setShowLoader(false);
      return; // Exit the function without proceeding
    }

    const willDelete = await swal({
      title: "Confirm Update?",
      text: `Are you sure you want to change the shift time from ${oldShiftTime} to ${formatTime(
        startTimeedit
      )}-${formatTime(endTimeedit)} for ${assignedempeditobj.name}`,
      icon: "warning",
      buttons: ["Cancel", "Update"],
    });

    if (willDelete) {
      // setNewShiftTime(newShiftTimeFormatted);

      console.log(assignedempeditobj);
      console.log(oldShiftTime);
      console.log(newShiftTime);

      var bodyFormData = {
        location: assignedempeditobj.location,
        selectedWeekDate: assignedempeditobj.weekdate,
        weekdate: dateRange.startDate,
        weekday: assignedempeditobj.weekday,
        emp_id: assignedempeditobj.id,
        newShiftTime: newShiftTime,
        oldShiftTime: oldShiftTime,
      };
      await makePostRequest("schedular/updateEmployeeLocalShifts", bodyFormData)
        .then((response) => {
          if (response.data) {
            swal("Success", "Record updated successfully", "success", {
              timer: 3000,
              buttons: false,
            });
            closeEdit();
            handleCloseChangeShift();
            openShifViewDetail(dateRange.startDate, dateRange.endDate);
            setShowLoader(false);
          }
        })
        .catch((err) => {
          console.log(err);
          setShowLoader(false);
        });
    }
  };

  const closeEdit = () => {
    setAssignedEmpEditObject({});
    setOldShiftTime(null);
    setStartTime(null);
    setEndTime(null);

    document.getElementById("EditSftId")?.classList.remove("ShowEditSftDiv");
  };

  const handleChangeShiftLabel = (e) => {
    setFilterFreq(e.target.value);
    setEditSlot((prevShift) => ({
      ...prevShift,
      frequency_id: e.target.value,
    }));
  };

  const AddUpdateShift = async (addeditflag, slot) => {
    // console.log(slot);
    slot.pending_emp_count = slot.required_emp_count - slot.assigned_emp_count;
    // return false;

    // console.log(slot);
    // return false;

    const apiEndpoint =
      addeditflag === "edit"
        ? "schedular/updateLocalShifts"
        : "schedular/addLocalShift";
    const shift =
      addeditflag === "edit"
        ? slot
        : {
            Key: Date.now(),
            shifttime: `${formatTime(startTimeedit)}-${formatTime(
              endTimeedit
            )}`,
            frequency_id: filterFreq,
            pending_emp_count: empCount,
            assigned_emp_count: 0,
            standby_employees: [],
            assigned_employees: [],
            required_emp_count: empCount,
            shiftadded: "Local",
            localshiftweekdate: selectedWeekDate,
          };

    let bodyFormData = {
      location: locationName,
      weekday: selectedWeekDay,
      updatedShift: addeditflag === "edit" ? shift : undefined,
      shift: addeditflag !== "edit" ? shift : undefined,
      weekdate: dateRange.startDate,
      selectedWeekDate: selectedWeekDate,
      apiname: "getWeeklyShiftStatusGrouped",
    };

    console.log(bodyFormData);
    console.log(apiEndpoint);
    // return false;

    try {
      const response = await makePostRequest(apiEndpoint, bodyFormData);

      if (response.data) {
        swal(
          "Success",
          addeditflag === "edit" ? "Update complete!" : "New shift added!",
          "success",
          {
            buttons: false,
          }
        );

        setOpen(false);
        window.location.reload();
      }
    } catch (err) {
      console.error(err);
    }
  };

  const [startTimeedit, setStartTime] = React.useState();
  const [endTimeedit, setEndTime] = React.useState();
  const [filterFreq, setFilterFreq] = useState("");
  const [open, setOpen] = React.useState(false);
  const [flag, setFlag] = React.useState("");
  const [editslot, setEditSlot] = React.useState({});
  const [addslot, setAddSlot] = React.useState({});
  const handleClickOpen = (slot, flag, location) => {
    // alert("flag",flag)
    setFlag(flag);

    if (flag == "edit") {
      getAllFrequancyList(location, slot.frequency_id);
      setFilterFreq(slot.frequency_id);
      const shiftTiming = slot.shifttime; // e.g., "6:00 PM-8:00 PM"
      const [startTimeStr, endTimeStr] = shiftTiming
        ? shiftTiming.split("-")
        : [null, null];

      // Parse the start and end times
      const startTime = startTimeStr
        ? parseTimeString(startTimeStr.trim())
        : null;
      const endTime = endTimeStr ? parseTimeString(endTimeStr.trim()) : null;

      setStartTime(startTime);
      setEndTime(endTime);
      setEditSlot(slot);
    } else {
      getAllFrequancyList(location, "");
      setFilterFreq("");
    }

    setOpen(true);
  };

  // Define a function to parse time string
  function parseTimeString(timeStr) {
    return parse(timeStr, "hh:mm aa", new Date());
  }

  const handleClose = () => {
    setOpen(false);
  };

  //For Scroll Add Class
  const [scrolling2, setScrolling2] = useState(false);

  useEffect(() => {
    const handleScroll2 = () => {
      if (window.scrollY > 170) {
        // Adjust this value based on your needs
        setScrolling2(true);
      } else {
        setScrolling2(false);
      }
    };
    window.addEventListener("scroll", handleScroll2);
    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll2);
    };
  }, []);
  // End

  const handlePublish = async () => {
    console.log("slotfilled");
    let bodyFormData = {
      employee_id: JSON.parse(localStorage.getItem("userId")),
    };
    await makePostRequest("schedular/publishSchedule", bodyFormData)
      .then((response) => {
        if (response.data) {
          swal(
            "Success",
            "Notification send to all employees successfully",
            "success"
          );
          getWeeklyEmployeeStatusConsolidatedView(
            "",
            dateRange.startDate,
            dateRange.endDate
          );
          openShifViewDetail(dateRange.startDate, dateRange.endDate);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [iterationcount,setIterationCount]=useState(0);
  const getIterationCountFunc=async(startDate,endDate)=>
  {
    try{
      let bodyFormData={
        startDate,
        endDate
       }
        
           // Retrieve the last iteration count from localStorage, default to 1 if not found
           let responseiteration = await makePostRequest(
            "schedular/getIterationCount",
           bodyFormData
          );

         
          setIterationCount(responseiteration.data.data);
          if(responseiteration.data.data==3)
          {
            setIterationReached(true);
          }

          if(isNextWeek(startDate) && responseiteration.data.data==0)
            {
              swal("Info", "You can use the Autoscript feature to automatically assign employees to shifts. You can run it up to three times and review three different assignment variations using the calendar icon. After reviewing, you can finalize one of the generated assignments or ignore them and proceed with manual assignments.", "info");
            }
      }
      catch (err) {
        console.log(err);
      }
  }

  const confirmIgnoreAction=async(action,iteration)=>{
    let text= action=='Ignore' ? "Are you sure you don't want to use any of the generated preview of the schedule assignment?" : "Are you sure you want to use the selected preview of the schedule assignment?";

    setShowLoader(true);
    try {
      
      // return false;
      let bodyFormData={
        action:action,
        iteration:iteration, 
        startDate:dateRange.startDate, 
        endDate:dateRange.endDate
      }
      swal({
            title:action,
            text: text,
            icon: "info",
            buttons: true,
            dangerMode: true,
          }).then(async (willuse) => {
            if (willuse) {
              const response = await makePostRequest(`schedular/assignmentFinal`,bodyFormData);
              console.log(response.data);
  
              if (response.data) {

                swal("Success", "Action performed successfully.", "success");
                setShowLoader(false);
              } else {
                swal("Info", "Something went wrong", "info");
                setShowLoader(false);
              }
              getWeeklyEmployeeStatusConsolidatedView("",dateRange.startDate,dateRange.endDate);
              getIterationCountFunc(dateRange.startDate,dateRange.endDate);
              openBottMainClick();
                    
        }

    });
     
     
    } catch (err) {
      console.log(err);
    }
  }

  const [iterationreached,setIterationReached]=useState(false);
  const handleAutoScript = async () => {
    setShowLoader(true);
  
    try {
      console.log("iter: "+iterationcount)
      
      let iteration = parseInt(iterationcount || "0", 10);
      iteration++; // Increment iteration count
      console.log(iteration);
      // return false;

      const response = await makeGetRequest(`schedular/autoAssignShifts/${iteration}/${dateRange.startDate}/${dateRange.endDate}`);
      console.log(response.data.data);
  
      if (response.data.data !== 0) {
        swal("Success", response.data.data.message, "success");
        setShowLoader(false);
      } else {
        swal("Info", "Employee availability not sufficient to assign the shifts", "info");
        setShowLoader(false);
      }
  
      if(iteration==3)
      {
        setIterationReached(true);
      }
      getWeeklyEmployeeStatusConsolidatedView(
        "",
        dateRange.startDate,
        dateRange.endDate
      );
      openShifViewDetail(dateRange.startDate, dateRange.endDate);
      getIterationCountFunc(dateRange.startDate,dateRange.endDate);
    } catch (err) {
      console.log(err);
    }
  };

  function openBottMainClick() {
    setSlotDetailView([]);
    setSlotDetailView2([]);
    setSlotDetailView3([]);
    openShifViewDetail(dateRange.startDate, dateRange.endDate);
    setSlotDetailViewMessage("Please wait...")
    var element = document.getElementById("openBottMainId");
    element.classList.toggle("openBottMainIntro");
  }

  

  const dayMapping = {
    Mon: "Monday",
    Tue: "Tuesday",
    Wed: "Wednesday",
    Thu: "Thursday",
    Fri: "Friday",
    Sat: "Saturday",
    Sun: "Sunday",
  };
  const [slotdetails, setSlotDetails] = useState([]);
  const [selectedWeekDay, setSelectedWeekDay] = useState("");
  const [selectedWeekDate, setSelectedWeekDate] = useState();
  const [locationName, setLocationName] = useState([]);
  const openShifView = async (location, index, dayselected) => {
    let obj = {
      weekdate: dayselected.formattedDayFull,
      weekday: dayMapping[dayselected.formattedDayOfWeek],
      location: location,
      startDate: dateRange.startDate,
      endDate: dateRange.endDate,
    };
    setSelectedWeekDay(dayMapping[dayselected.formattedDayOfWeek]);
    setSelectedWeekDate(dayselected.formattedDayFull);
    setLocationName(location);
    getAllFrequancyList(location, "");
    const startOfWeek = moment().startOf("isoWeek").format("YYYY-MM-DD"); // Week starts on Monday

    if (startDate <= startOfWeek) {
      // alert("in filter");
      openShifViewFilter(location, index, dayselected);
    } else {
      // alert("in template")
      await makePostRequest("schedular/getShiftStatus", obj)
        .then((response) => {
          console.log(response.data.data);
          if (response.data) {
            setSlotDetails(response.data.data);
          } else {
            setSlotDetails([]);
          }
        })
        .catch((err) => {
          console.log(err);
          setSlotDetails([]);
        });

      var selectedElement = document.getElementById(`${location}_${index}`);
      // Remove 'acciveThead' from all other elements
      var allElements = document.querySelectorAll(".acciveThead"); // Select all elements with 'acciveThead' class
      allElements.forEach((el) => {
        if (el !== selectedElement) {
          el.classList.remove("acciveThead"); // Remove class from other elements
        }
      });

      // Toggle 'acciveThead' for the clicked element
      selectedElement.classList.toggle("acciveThead");

      var allElements = document.querySelectorAll(".acciveThead");

      if (allElements.length == 0) {
        var element = document.getElementById(`showShifViewMain${location}`);
        // element.classList.toggle("showShifViewSt");
        element.classList.remove("showShifViewSt");
      } else {
        var element = document.getElementById(`showShifViewMain${location}`);
        // element.classList.toggle("showShifViewSt");
        element.classList.add("showShifViewSt");
      }
    }
  };

  const openShifViewFilter = async (location, index, dayselected) => {
    let obj = {
      weekdate: dayselected.formattedDayFull,
      weekday: dayMapping[dayselected.formattedDayOfWeek],
      location: location,
      startDate: dateRange.startDate,
      endDate: dateRange.endDate,
    };

    console.log("Filtering for:", obj.location, obj.weekday);
    console.log("Stored Schedule Data:", slotdetailview);

    if (slotdetailview && slotdetailview.length > 0) {
      let filteredData = slotdetailview
        .filter((loc) => loc.location === obj.location) // Match location
        .flatMap((loc) => loc.weekdays) // Extract all weekdays
        .filter((day) => day.weekday === obj.weekday); // Match weekday

      console.log("Filtered Data:", filteredData);
      setSlotDetails(filteredData.length > 0 ? filteredData[0].shifts : []);
    } else {
      setSlotDetails([]);
    }

    var selectedElement = document.getElementById(`${location}_${index}`);
    var allElements = document.querySelectorAll(".acciveThead");

    allElements.forEach((el) => {
      if (el !== selectedElement) {
        el.classList.remove("acciveThead");
      }
    });

    selectedElement.classList.toggle("acciveThead");

    var updatedElements = document.querySelectorAll(".acciveThead");
    var element = document.getElementById(`showShifViewMain${location}`);

    if (updatedElements.length === 0) {
      element.classList.remove("showShifViewSt");
    } else {
      element.classList.add("showShifViewSt");
    }
  };

  //For Scroll Add Class
  const [scrolling, setScrolling] = useState(false);
  // Scroll event listener
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        // Jab window scroll y-axis par 100px se zyada ho, class add ho
        setScrolling(true);
      } else {
        setScrolling(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener on unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []); // Empty array means this effect runs once when component mounts

  // Toggle Table
  // function toggleTbl() {
  //   var element = document.getElementById("hideShowTbls");
  //   element.classList.toggle("showpreTbl");
  // }

  const [label, setLabel] = useState("Schedule For ");
  const [topclass, setTopClass] = useState("scheduleLocationBg");
  function toggleTbl() {
    // Select all elements with an id starting with 'hideShowTbls'
    const elements = document.querySelectorAll("[id^='hideShowTbls']");
    let isShown = false;
    // Loop through all selected elements and toggle the class
    elements.forEach(function (element) {
      if (!isShown && !element.classList.contains("showpreTbl")) {
        isShown = true; // Mark as shown if any element gains the class
      }
      element.classList.toggle("showpreTbl");
    });

    // Update the label based on the class presence
    if (isShown) {
      setLabel("Employees Availability For "); // Label when 'showpreTbl' is added
      setTopClass("empAvailabilityBg");
    } else {
      setLabel("Schedule For "); // Label when 'showpreTbl' is removed
      setTopClass("scheduleLocationBg");
    }
  }

  // End

  // Function to handle scroll
  const scrollToTarget = (targetId) => {
    // Get the target element
    const target = document.getElementById(targetId);

    // Scroll smoothly to the target element
    target.scrollIntoView({
      behavior: "smooth",
      block: "start", // Scroll to the top of the target section
    });

    // After scrolling, adjust the scroll position to stop at a fixed height
    setTimeout(() => {
      // Set a fixed offset to stop the scroll at a fixed height (e.g., 100px from the top)
      const fixedOffset = 90; // 100px offset from the top
      window.scrollBy(0, -fixedOffset); // Scroll up by 100px to stop at the desired position
    }, 1000); // Delay to ensure scroll finishes before adjusting
  };

  const [searchTerm, setSearchTerm] = useState("");
  const [searchLocation, setSearchLocation] = useState("");
  const searchEmp = (e, location) => {
    const searchTerm = e.target.value.toLowerCase(); // Convert to lowercase for case-insensitive matching
    setSearchTerm(searchTerm);
    setSearchLocation(location);
    // If the search term is empty, restore the original data for the specified location
    if (!searchTerm) {
      setViewData((prevData) => {
        const updatedData = { ...prevData };
        updatedData[location] = originalData[location]; // Restore original data for the location
        return updatedData;
      });
      return;
    }

    // Apply filter for the specified location
    setViewData((prevData) => {
      const updatedData = { ...prevData };

      // Filter employees for the specified location based on the search term
      updatedData[location] = originalData[location].filter((employee) =>
        employee.full_name.toLowerCase().includes(searchTerm)
      );

      return updatedData;
    });
  };

  const [srchloc, setSrchLoc] = useState("");
  const searchSlide = (index, location) => {
    // var element = document.getElementById("searchSlideId"+index);
    // element.classList.toggle("searchSlideClass");
    setSrchLoc(location);
    var element = document.getElementById("searchSlideId" + index);
    if (element) {
      element.classList.toggle("searchSlideClass");
    } else {
      console.error(`Element with ID "searchSlideId${index}" not found.`);
    }
  };

  const settings = {
    adaptiveHeight: true,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const settingsTablet = {
    focusOnSelect: true,
    slidesToScroll: 1,
    className: "center",
    centerMode: false,
    infinite: false,
    centerPadding: "0px",
    slidesToShow: 7,
    speed: 500,
  };

  const [totalempcount, setTotalEmpCount] = React.useState(0);
  async function getAllEmployeeList() {
    await makeGetRequest(
      "employee/employeesearch/" +
        localStorage.getItem("companyId") +
        "/created_at/desc/null/0/1000"
    )
      .then((response) => {
        if (response.data.data.res) {
          setTotalEmpCount(response.data.data.res.length);
        }
      })
      .catch((err) => {
        // setShowLoader(false);
      });
  }

  const handleTimeChange = (newTimeRange) => {
    // Handle the case where `timeRange` is null or invalid
    if (!newTimeRange || !Array.isArray(newTimeRange)) {
      setStartTime(null); // Reset to default or null
      setEndTime(null);
      return;
    }

    const [newStartTime, newEndTime] = newTimeRange;
    setStartTime(newStartTime);
    setEndTime(newEndTime);
    setNewShiftTime(`${formatTime(newStartTime)}-${formatTime(newEndTime)}`);
    // Update the shift timing in editshift
    setEditSlot((prevShift) => ({
      ...prevShift,
      shifttime: `${formatTime(newStartTime)}-${formatTime(newEndTime)}`,
    }));
  };
  // Format time functions
  const formatTime = (date) => {
    if (!date) return "";
    let hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12;
    const minutesStr = minutes < 10 ? `0${minutes}` : minutes;
    return `${hours}:${minutesStr} ${ampm}`;
  };

  const formatShiftTime = (shiftTime) => {
    if (shiftTime && shiftTime.length === 2) {
      return `${formatTime(shiftTime[0])}-${formatTime(shiftTime[1])}`;
    }
    return "";
  };

  // // Function to increment employee count
  // const incrementCount2 = (flag) => {

  //   setEditSlot((prevShift) => ({
  //     ...prevShift,
  //     required_emp_count: prevShift.required_emp_count + 1, // Increment emp_count
  //   }));
  // };

  // // Function to decrement employee count
  // const decrementCount2 = (flag) => {
  //   setEditSlot((prevShift) => ({
  //     ...prevShift,
  //     required_emp_count: prevShift.required_emp_count > 1 ? prevShift.required_emp_count - 1 : 1, // Decrement emp_count but ensure it stays above 1
  //   }));
  // };

  // Function to increment employee count
  const [empCount, setEmpCount] = useState(1);

  const incrementCount = () => {
    setEmpCount((prevCount) => prevCount + 1);
  };

  const decrementCount = () => {
    setEmpCount((prevCount) => Math.max(0, prevCount - 1)); // Ensures it doesn't go below 0
  };

  const incrementCount2 = () => {
    setEditSlot((prevShift) => ({
      ...prevShift,
      required_emp_count: prevShift.required_emp_count + 1,
    }));
  };

  // Function to decrement employee count
  const decrementCount2 = () => {
    setEditSlot((prevShift) => ({
      ...prevShift,
      required_emp_count: Math.max(0, prevShift.required_emp_count - 1),
    }));
  };

  const [freqList, setFreqList] = React.useState([]);
  async function getAllFrequancyList(locname, frequancyid) {
    if (locname == "") {
      await makeGetRequest("frequancy/getfrequancylist/frequancy_name/asc/null")
        .then((response) => {
          if (response.data.data.res) {
            console.log("freq>> " + response.data.data.res);
            if (response.data.data.res.length > 0) {
              setFreqList(response.data.data.res);
            } else {
              setFreqList([]);
            }
          }
        })
        .catch((err) => {
          //setShowLoader(false);
        });
    } else {
      //code to get used frequencies
      let frequencyIds = new Set(); // Using Set to store unique values

      // slotdetailview
      //   .filter(item => item.location === locname)
      //   .forEach(item => {
      //     item.weekdays.forEach(weekday => {
      //       weekday.shifts.forEach(shift => {
      //         frequencyIds.add(shift.frequency_id);  // Add frequency_id to Set
      //       });
      //     });
      //   });

      slotdetailview
        .filter((item) => item.location === locname)
        .forEach((item) => {
          item.weekdays
            .filter((day) => day.weekday === selectedWeekDay) // Match weekday
            .forEach((weekday) => {
              weekday.shifts.forEach((shift) => {
                frequencyIds.add(shift.frequency_id); // Add frequency_id to Set
              });
            });
        });

      let usedFrequencyIdString = Array.from(frequencyIds);
      //end code

      let bodyFormData = {
        location: locname,
        frequencyId: frequancyid,
        usedFrequencyIdString: usedFrequencyIdString,
        weekday: selectedWeekDay,
      };

      console.log(bodyFormData);

      // return false;

      await makePostRequest(
        "schedular/getUnusedFrequenciesByLocationForDump",
        bodyFormData
      )
        .then((response) => {
          if (response.data) {
            setFreqList(response.data.data);
          } else {
            setFreqList([]);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  // async function getAllFrequancyList() {

  //     await makeGetRequest("frequancy/getfrequancylist/frequancy_name/asc/null")
  //       .then((response) => {
  //         if (response.data.data.res) {
  //           console.log("freq>> " + response.data.data.res);
  //           if (response.data.data.res.length > 0) {
  //             setFreqList(response.data.data.res);
  //           } else {
  //             setFreqList([]);
  //           }
  //         }
  //       })
  //       .catch((err) => {
  //         //setShowLoader(false);
  //       });

  // }

  // For Schedul Preview
  const [Preview, setOpenPreview] = React.useState(false);

  const handleClickPreview = () => {
    setOpenPreview(true);
  };

  const handleClosePreview = () => {
    setOpenPreview(false);
  };
  // End

  const changeAssingedShiftofEmp = async (
    name,
    empid,
    profile_pic,
    weekday,
    weekdate
  ) => {
    console.log(empid);
    console.log(shiftloc);
    console.log(weekdate);
    console.log(weekday);

    console.log(shiftloctimevalue);

    const newShift = parseShift(shiftloctimevalue);

    console.log(ashiftsinfo);
    console.log(newShift);
    // Check for overlap
    if (isOverlapping(ashiftsinfo, newShift)) {
      console.log("The new shift overlaps with an existing shift.");
      swal(
        "Warning",
        "The new shift overlaps with an existing shift.",
        "warning"
      );
      return;
    } else {
      console.log("No overlap with existing shifts.");
    }

    const arr = shiftloctimevalue.split("=>");

    console.log(">>", arr);
    let dumpdata = [];
    if (startDate <= startOfWeekInitial) {
      dumpdata = slotdetailview;
    } else {
      dumpdata = [];
    }

    let bodyFormData = {
      shifttime: arr[0], //shiftloctime : previously it was shift location only but now attached with status
      location: shiftloc,
      weekdate: weekdate,
      weekday: weekday,
      startDate: dateRange.startDate,
      endDate: dateRange.endDate,
      dumpdata: dumpdata,
    };

    let confirm = await makePostRequest(
      "schedular/validateEmployeeCountForChangeShift",
      bodyFormData
    );

    if (confirm.data.data.isValid == false) {
      let message = "";
      if (confirm.data.data.empCount > 1) {
        message = `Shift for location ${shiftloc} having time ${shiftloctime} already have ${confirm.data.data.empCount} employees assigned. If you want to change shift of ${name}, you need to first release one of already assigned employees.`;
      } else {
        message = `Shift for location ${shiftloc} having time ${shiftloctime} already have ${confirm.data.data.empCount} employee assigned. If you want to change shift of ${name}, you need to first release the employee.`;
      }

      swal("Warning", message, "warning");
    } else {
      //call api to assign employee
      let bodyFormData1 = {
        shifttime: arr[0],
        emp_id: empid,
        empName: name,
        profilePic: profile_pic,
        location: shiftloc,
        weekdate: weekdate,
        weekday: weekday,
        frequancyid: arr[2],
        sch_id: arr[3],
        startDate: dateRange.startDate,
        endDate: dateRange.endDate,
      };

      console.log(bodyFormData1);

      await makePostRequest("schedular/addEmployeeToScheduler", bodyFormData1)
        .then((response) => {
          if (response.data) {
            swal("Success", "Employee Shift Changed Successfully", "success", {
              timer: 2500,
              buttons: false,
            });
            setChangeDetected(true);
            setOpenChangeShift(false);
            // getWeeklyEmployeeStatusConsolidatedView(
            //   "",
            //   dateRange.startDate,
            //   dateRange.endDate
            // );
            document.querySelectorAll(".showShifViewSt").forEach((el) => {
              el.classList.remove("showShifViewSt");
            });
            document.querySelectorAll(".acciveThead").forEach((el) => {
              el.classList.remove("acciveThead");
            });

            openShifViewDetail(dateRange.startDate, dateRange.endDate);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  useEffect(() => {
    if (changeDetected) {
      // If changeDetected is true, call getWeeklyEmployeeStatusConsolidatedView
      //  openShifViewDetail(dateRange.startDate,dateRange.endDate);
      getWeeklyEmployeeStatusConsolidatedView(
        "",
        dateRange.startDate,
        dateRange.endDate
      );
      setChangeDetected(false); // Reset after the action is triggered
    }
  }, [changeDetected]); // This will trigger every time changeDetected changes

  //Release employee
  const releaseEmpBeforeChangeShift = async (
    name,
    emp_id,
    shifttime,
    location,
    weekday,
    weekdate,
    flag
  ) => {
    let bodyFormData = {
      shifttime: shifttime,
      emp_id: emp_id,
      location: location,
      weekdate: weekdate,
      weekday: weekday,
      startDate: dateRange.startDate,
    };

    await makePostRequest("schedular/removeEmployeeFromScheduler", bodyFormData)
      .then((response) => {
        if (response.data) {
          if (flag == "self") {
            setAssignShiftsInfo((prevShiftsInfo) =>
              prevShiftsInfo.filter((shift) => shift.shifttime !== shifttime)
            );
          }

          if (flag == "other") {
            setOtherShiftInfo((prevShiftsInfo) =>
              prevShiftsInfo.filter((emp) => emp.emp_id !== emp_id)
            );
          }

          swal(
            "Success",
            `${name} is released from the location ${location} for the shift time ${shifttime}  Successfully`,
            "success",
            {
              timer: 2500,
              buttons: false,
            }

            //getWeeklyEmployeeStatusConsolidatedView("",dateRange.startDate,dateRange.endDate)
          );
          setChangeDetected(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //Release employee
  const releaseEmp = async (
    name,
    emp_id,
    releaseempname,
    assignempid,
    shifttime,
    location,
    weekday,
    weekdate
  ) => {
    let bodyFormData = {
      shifttime: shifttime,
      emp_id: emp_id,
      location: location,
      weekdate: weekdate,
      weekday: weekday,
      startDate: dateRange.startDate,
    };

    await makePostRequest("schedular/removeEmployeeFromScheduler", bodyFormData)
      .then((response) => {
        if (response.data) {
          swal(
            "Success",
            `${releaseempname} is released from the location ${location} for the shift time ${shifttime}  Successfully`,
            "success",
            {
              timer: 2500,
              buttons: false,
            }
          );

          // Refresh the data for the popup
          handleClickAssignShift({
            name: name,
            id: assignempid,
            location: location,
            weekdate: weekdate,
            weekday: weekday,
          });

          setChangeDetected(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const assignShiftToSB = async (
    name,
    empid,
    profile_pic,
    shifts,
    weekday,
    weekdate
  ) => {
    console.log(empid);
    console.log(shifts);
    console.log(weekdate);
    console.log(weekday);

    let bodyFormData = {
      shifttime: shifts.shifttime,
      empcount: shifts.emp_count,
      location: shifts.shift_location,
      weekdate: weekdate,
      weekday: weekday,
    };

    let confirm = await makePostRequest(
      "schedular/validateEmployeeCount",
      bodyFormData
    );

    console.log(confirm.data.data.isMatching);

    if (confirm.data.data.isMatching == true) {
      const message = `Shift ${shifts.shifttime} already have ${shifts.emp_count} employees assigned. If you want to assign ${name} to this shift, you need to first release any one of the already assigned employees.`;
      swal("Warning", message, "warning");
    } else {
      const newShift = parseShift(shifts.shifttime);

      console.log(newShift);
      console.log("ashiftsinfo", ashiftsinfo);
      // Check for overlap

      if (isOverlapping(ashiftsinfo, newShift)) {
        console.log("The new shift overlaps with an existing shift.");
        swal(
          "Warning",
          "The new shift overlaps with an existing shift.",
          "warning"
        );
        return;
      } else {
        console.log("No overlap with existing shifts.");
      }

      //call api to assign employee
      let bodyFormData1 = {
        shifttime: shifts.shifttime,
        emp_id: empid,
        empName: name,
        profilePic: profile_pic,
        location: shifts.shift_location,
        weekdate: weekdate,
        weekday: weekday,
        frequancyid: shifts.frequency_id,
        sch_id: shifts.sch_id,
        startDate: dateRange.startDate,
        endDate: dateRange.endDate,
      };

      console.log(bodyFormData1);

      //  return false

      await makePostRequest("schedular/addEmployeeToScheduler", bodyFormData1)
        .then((response) => {
          if (response.data) {
            swal("Success", "Employee Assigned Successfully", "success", {
              timer: 2500,
              buttons: false,
            });
            setChangeDetected(true);
            setOpenAssignShift(false);
            getWeeklyEmployeeStatusConsolidatedView(
              "",
              dateRange.startDate,
              dateRange.endDate
            );
            openShifViewDetail(dateRange.startDate, dateRange.endDate);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  // Assign Shift Moddal
  const [AssignShift, setOpenAssignShift] = React.useState(false);
  const [sbobj, setSBObject] = React.useState({});
  const [sbshiftsinfo, setSBShiftsInfo] = React.useState([]);
  const [sbushiftsinfo, setSBUShiftsInfo] = React.useState([]);

  const handleClickAssignShift = async (obj) => {
    console.log("Refreshing data for popup:", obj);
    setSBObject(obj);

    let dumpdata = [];
    if (startDate <= startOfWeekInitial) {
      dumpdata = slotdetailview;
    } else {
      dumpdata = [];
    }

    let bodyFormData = {
      emp_id: obj.id,
      location: obj.location,
      weekdate: obj.weekdate,
      weekday: obj.weekday,
      startDate: dateRange.startDate,
      endDate: dateRange.endDate,
      dumpdata: dumpdata,
    };

    // alert(obj.weekstatus)

    await makePostRequest(
      "schedular/getEmployeeAssignedShiftsForSB",
      bodyFormData
    )
      .then((response) => {
        if (response.data) {
          console.log(response.data.data);
          setAssignShiftsInfo(response.data.data);

          // setOpenChangeShift(true);
        } else {
          setAssignShiftsInfo([]);
        }
      })
      .catch((err) => {
        setAssignShiftsInfo([]);
        console.log(err);
      });

    await makePostRequest("schedular/getShiftDetailsForSB", bodyFormData)
      .then((response) => {
        if (response.data) {
          console.log(response.data);
          setSBShiftsInfo(response.data.data);
          // setOpenAssignShift(true);
        } else {
          setSBShiftsInfo([]);
        }
      })
      .catch((err) => {
        setSBShiftsInfo([]);
        console.log(err);
      });

    await makePostRequest(
      "schedular/getShiftsWithoutAvailabilityForSB",
      bodyFormData
    )
      .then((response) => {
        if (response.data) {
          console.log(response.data);
          setSBUShiftsInfo(response.data.data);
          // setOpenAssignShift(true);
        } else {
          setSBUShiftsInfo([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });

    setOpenAssignShift(true);
  };

  const handleCloseAssignShift = () => {
    setOpenAssignShift(false);
    getWeeklyEmployeeStatusConsolidatedView(
      "",
      dateRange.startDate,
      dateRange.endDate
    );
  };
  // End

  // Function to parse the shift string
  function parseShift(shiftStr) {
    const [timeRange] = shiftStr.split("=>"); // Split at "=>"
    const [start, end] = timeRange.split("-"); // Split start and end times
    return { start: start.trim(), end: end.trim() };
  }

  // Function to convert time to a comparable Date object
  // function convertToDate(timeStr) {
  //   const [time, modifier] = timeStr.split(" "); // Split time and AM/PM
  //   let [hours, minutes] = time.split(":").map(Number);

  //   // Adjust hours for AM/PM
  //   if (modifier === "PM" && hours < 12) hours += 12;
  //   if (modifier === "AM" && hours === 12) hours = 0;

  //   return new Date(1970, 0, 1, hours, minutes);
  // }

  // // Function to check overlap
  // function isOverlapping(existingShifts, newShift) {
  //   const newStart = convertToDate(newShift.start);
  //   const newEnd = convertToDate(newShift.end);

  //   return existingShifts.some((shift) => {
  //     const [existingStartStr, existingEndStr] = shift.shifttime.split("-");
  //     const existingStart = convertToDate(existingStartStr.trim());
  //     const existingEnd = convertToDate(existingEndStr.trim());

  //     alert(existingStart +"<"+ newEnd +"&&"+ newStart +"<"+ existingEnd)

  //     // Check if there's an overlap
  //     return existingStart < newEnd && newStart < existingEnd;
  //   });
  // }

  //   function convertToDate(timeStr) {
  //     const [time, modifier] = timeStr.trim().split(" "); // Split time and AM/PM
  //     let [hours, minutes] = time.split(":").map(Number);

  //     if (modifier === "PM" && hours < 12) hours += 12;
  //     if (modifier === "AM" && hours === 12) hours = 0;

  //     return new Date(1970, 0, 1, hours, minutes, 0); // Set fixed date for comparison
  // }

  // // Function to check shift overlap
  // function isOverlapping(existingShifts, newShift) {
  //     const newStart = convertToDate(newShift.start);
  //     const newEnd = convertToDate(newShift.end);

  //     return existingShifts.some((shift) => {
  //         const [existingStartStr, existingEndStr] = shift.shifttime.split("-");

  //         const existingStart = convertToDate(existingStartStr.trim());
  //         const existingEnd = convertToDate(existingEndStr.trim());

  //         console.log(`Comparing: ${existingStart} < ${newEnd} && ${newStart} < ${existingEnd}`);

  //         // Check for overlap condition
  //         return existingStart < newEnd && newStart < existingEnd;
  //     });
  // }

  // Function to convert time to a comparable Date object
  function convertToDate(timeStr) {
    const [time, modifier] = timeStr.trim().split(" "); // Split time and AM/PM
    let [hours, minutes] = time.split(":").map(Number);

    if (modifier === "PM" && hours < 12) hours += 12;
    if (modifier === "AM" && hours === 12) hours = 0;

    return new Date(1970, 0, 1, hours, minutes, 0); // Fixed date for comparison
  }

  // Function to check shift overlap
  function isOverlapping(existingShifts, newShift) {
    const newStart = convertToDate(newShift.start);
    const newEnd = convertToDate(newShift.end);

    return existingShifts.some((shift) => {
      const [existingStartStr, existingEndStr] = shift.shifttime.split("-");

      const existingStart = convertToDate(existingStartStr.trim());
      const existingEnd = convertToDate(existingEndStr.trim());

      console.log(
        `Comparing: ${existingStart} < ${newEnd} && ${newStart} < ${existingEnd}`
      );

      // Corrected overlap conditions:
      return (
        (newStart >= existingStart && newStart < existingEnd) || // Starts within existing shift
        (newEnd > existingStart && newEnd <= existingEnd) || // Ends within existing shift
        (newStart <= existingStart && newEnd >= existingEnd) // Completely overlaps existing shift
      );
    });
  }

  const [shiftloctime, setShiftLocTime] = React.useState();
  const [shiftloctimevalue, setShiftLocTimeValue] = React.useState();
  const [othershiftinfo, setOtherShiftInfo] = React.useState([]);
  const handleChangeShiftLocationTime = async (
    e,
    id,
    location,
    weekdate,
    weekday
  ) => {
    setShiftLocTimeValue(e.target.value);

    const shiftloctime = e.target.value;
    const arr = shiftloctime.split("=>");

    setShiftLocTime(arr[0]);

    let bodyFormData = {
      emp_id: id,
      shifttime: arr[0],
      location: shiftloc,
      weekdate: weekdate,
      weekday: weekday,
    };

    await makePostRequest(
      "schedular/getAssignedEmpDetailsForShift",
      bodyFormData
    )
      .then((response) => {
        if (response.data) {
          console.log(response.data);
          setOtherShiftInfo(response.data.data);
          // setOpenAssignShift(true);
        } else {
          setOtherShiftInfo([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [shiftsfromloc, setShiftsFromLocation] = React.useState([]);
  const [shiftloc, setShiftLoc] = React.useState();
  const handleChangeShiftLocation = async (
    e,
    locname,
    empname,
    emp_id,
    weekday,
    weekdate
  ) => {
    setShiftLocTime("");
    setShiftLocTimeValue("");
    setShiftsFromLocation("");
    const selectedloc = e;
    setShiftLoc(selectedloc);
    if (selectedloc != locname) {
      swal(
        "Warning",
        `Are you sure, you want to change the shift location of ${empname} from ${locname} to ${selectedloc}?`,
        "warning"
      );
    }

    let dumpdata = [];
    if (startDate <= startOfWeekInitial) {
      dumpdata = slotdetailview;
    } else {
      dumpdata = [];
    }

    const obj = {
      location: selectedloc,
      emp_id: emp_id,
      weekday: weekday,
      weekdate: weekdate,
      startDate: dateRange.startDate,
      endDate: dateRange.endDate,
      extractedlogicfordump: dumpdata,
    };

    //  alert(JSON.stringify(obj));

    await makePostRequest("schedular/getShiftTimesForLocation", obj)
      .then((response) => {
        if (response.data) {
          console.log(response.data);
          setShiftsFromLocation(response.data.data);
        } else {
          setShiftsFromLocation([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // Change Shift Moddal
  const [ChangeShift, setOpenChangeShift] = React.useState(false);
  const [aobj, setAssignObject] = React.useState({});
  const [ashiftsinfo, setAssignShiftsInfo] = React.useState([]);
  const [ashiftsloc, setAssignShiftsLoc] = React.useState([]);
  const [defaultloc, setDefaultLoc] = React.useState("");
  const handleClickChangeShift = async (obj) => {
    setShiftLoc("");
    setShiftLocTime("");
    setShiftLocTimeValue("");
    setShiftsFromLocation("");
    console.log(obj);
    setAssignObject(obj);
    setDefaultLoc(obj.location);
    setShiftLoc(obj.location);
    let FormData = {
      emp_id: obj.id,
      location: obj.location,
      startDate: dateRange.startDate,
      endDate: dateRange.endDate,
    };

    await makePostRequest("schedular/getDistinctLocationsForEmployee", FormData)
      .then((response) => {
        if (response.data) {
          console.log(response.data);
          setAssignShiftsLoc(response.data.data);
        } else {
          setAssignShiftsLoc([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });

    let bodyFormData = {
      emp_id: obj.id,
      location: obj.location,
      weekdate: obj.weekdate,
      weekday: obj.weekday,
    };

    // alert(obj.weekstatus)

    if (obj.weekstatus == "U") {
      await makePostRequest(
        "schedular/getEmployeeAssignedShiftsForU",
        bodyFormData
      )
        .then((response) => {
          if (response.data) {
            console.log(response.data.data);
            setAssignShiftsInfo(response.data.data);

            // setOpenChangeShift(true);
          } else {
            setAssignShiftsInfo([]);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      await makePostRequest("schedular/getEmployeeAssignedShifts", bodyFormData)
        .then((response) => {
          if (response.data) {
            console.log(response.data.data);
            setAssignShiftsInfo(response.data.data);

            // setOpenChangeShift(true);
          } else {
            setAssignShiftsInfo([]);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }

    let dumpdata = [];
    if (startDate <= startOfWeekInitial) {
      dumpdata = slotdetailview;
    } else {
      dumpdata = [];
    }

    // new code in case default location selected then we need shifttimes as well
    const objdefaultloc = {
      location: obj.location,
      emp_id: obj.id,
      weekday: obj.weekday,
      weekdate: obj.weekdate,
      startDate: dateRange.startDate,
      endDate: dateRange.endDate,
      extractedlogicfordump: dumpdata,
    };
    await makePostRequest("schedular/getShiftTimesForLocation", objdefaultloc)
      .then((response) => {
        if (response.data) {
          console.log(response.data);
          setShiftsFromLocation(response.data.data);
        } else {
          setShiftsFromLocation([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    //

    setOpenChangeShift(true);
  };

  const handleCloseChangeShift = () => {
    setOpenChangeShift(false);
    getWeeklyEmployeeStatusConsolidatedView(
      "",
      dateRange.startDate,
      dateRange.endDate
    );
  };
  // End

  const requestSwapActionAdmin = async (empswapofferid, status) => {
    const adminid = parseInt(localStorage.getItem("userId"));
    let obj = {
      empswapofferid,
      status,
      adminid,
      startDate: dateRange.startDate,
      endDate: dateRange.endDate,
    };

    await makePostRequest("schedular/swapRequestApprovalAdmin", obj)
      .then((response) => {
        let message;
        if (status)
          { 
            message = `Request Approved.`;
            setChangeDetected(true);
          }
        else
        {
           message = `Request Rejected.`;
        }

        if (response.data) {
          swal("Success", message, "success", {
            buttons: true,
          });
        }

        getSwapOfferRequestsAdmin();
        getWeeklyEmployeeStatusConsolidatedView(
          "",
          dateRange.startDate,
          dateRange.endDate
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [request, setRequest] = React.useState([]);
  const getSwapOfferRequestsAdmin = async () => {
    await makePostRequest("schedular/getSwapOfferRequestsAdmin")
      .then((response) => {
        if (response.data) {
          console.log(response.data.data);
          setRequest(response.data.data);
        } else {
          setRequest([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [showLoader, setShowLoader] = React.useState(false);
  const [viewdata, setViewData] = React.useState([]);
  const [originalData, setOriginalData] = useState(null); // State to hold original data
  const [loc, setLoc] = React.useState([]);
  const [assingedflag,setAssignedFlag]=useState(false); //this is declared to identify if we need to consider iterations or main table to show summary info on calender icon

  let arr = [];
 
  const fetchData = async (url, bodyFormData, isDump = false) => {
    setShowLoader(true);
    try {
      const response = await makePostRequest(url, bodyFormData);
      if (response.data) {
        setShowLoader(false);
        return isDump ? response.data.data : response.data.data.data;
      } else {
        setShowLoader(false);
      }
      // return null;
    } catch (error) {
      console.error(`Error in ${url}:`, error);
      setShowLoader(false);
      return null;
    }
  };

  const getWeeklyEmployeeStatusConsolidatedView = async (
    location,
    startDate,
    endDate
  ) => {
    setShowLoader(true);
    setHiddenRows([]);
    let arr = [];

    const startOfWeek = moment().startOf("isoWeek").format("YYYY-MM-DD"); // Week starts on Monday
    let viewData = null;

    if (startDate <= startOfWeek) {
      let bodyFormData;
      //if change detected then first call getconsloidatedview to dump the latest response and then get it
      // alert(changeDetected);
      if (changeDetected) {
        // If no data from dump, fetch from the main API
          // alert("in if changedetected");
        bodyFormData = {
          location: location,
          startDate: startDate,
          endDate: endDate,
        };
        viewData = await fetchData(
          "schedular/getWeeklyEmployeeStatusConsolidatedView",
          bodyFormData
        );
        //  setChangeDetected(false);
      } else {
        bodyFormData = {
          apiname: "getWeeklyEmployeeStatusConsolidatedView",
          startofweek: startOfWeek,
        };
        viewData = await fetchData(
          "schedular/getDumpDataFromScheduler",
          bodyFormData,
          true
        );
      }

      if (!viewData || Object.keys(viewData).length === 0) {
        // If no data from dump, fetch from the main API

        bodyFormData = {
          location: location,
          startDate: startDate,
          endDate: endDate,
        };
        viewData = await fetchData(
          "schedular/getWeeklyEmployeeStatusConsolidatedView",
          bodyFormData
        );
        setChangeDetected(false);
      }
    } else {
      let bodyFormData = {
        location: location,
        startDate: startDate,
        endDate: endDate,
      };
      viewData = await fetchData(
        "schedular/getWeeklyEmployeeStatusConsolidatedView",
        bodyFormData
      );
    }

    if (viewData && Object.keys(viewData).length > 0) {
      let hasAssigned = false;

      Object.values(viewData).forEach((employees) => {
        employees.forEach((employee) => {
          if (employee.weekly_status.some((status) => status.status === "A")) {
            hasAssigned = true;
          }
        });
      });
    
      setAssignedFlag(hasAssigned);
      if (searchTerm != "" && searchLocation != "") {
        // Apply filter for the specified location
        setViewData((prevData) => {
          const updatedData = { ...prevData };

          // Filter employees for the specified location based on the search term
          updatedData[searchLocation] = originalData[searchLocation].filter(
            (employee) => employee.full_name.toLowerCase().includes(searchTerm)
          );

          return updatedData;
        });
      } else {
        setViewData(viewData);
      }
      setOriginalData(viewData);
      Object.keys(viewData).forEach((loc) => {
        arr.push(loc);
      });
      setLoc(arr);
    } else {
      setViewData([]);
      setOriginalData([]);
      setLoc([]);
    }

    setShowLoader(false);
  };

  const settings2 = {
    focusOnSelect: true,
    slidesToScroll: 1,
    className: "center",
    centerMode: false,
    infinite: false,
    centerPadding: "0px",
    slidesToShow: 7,
    speed: 500,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const [currentweekindex, setCurrentWeekIndex] = React.useState(0);
  const [availweekstartdate, Setavailweekstartdate] = React.useState();
  const getStartWeekdate = () => {
    makeGetRequest("employee/getStartingWeekDateAssigned")
      .then((response) => {
        console.log(response.data.data);
        if (response.data.data) {
          Setavailweekstartdate(response.data.data);
        } else {
          Setavailweekstartdate();
        }
      })
      .catch((err) => {});
  };

  const getMondays = (numWeeks) => {
    const mondays = [];
    let currentDate = moment();

    //  // currentDate = moment();
    //    if (availweekstartdate == "") {
    //      currentDate = moment();
    //    } else {
    //      currentDate = moment(availweekstartdate);
    //    }
    // alert(currentDate);
    // Find the previous Monday (or current day if it's Monday)
    // const currentMonday = currentDate.startOf("week").add(1, "days");
    // const nextMonday = currentDate.startOf("week").add(1, "weeks").add(1, "days");

    // Check if today is Sunday
    let currentMonday =
      currentDate.day() === 0
        ? currentDate.startOf("week").subtract(6, "days") // Get the current week's Monday if today is Sunday
        : currentDate.startOf("week").add(1, "days"); // Otherwise, get the normal Monday

    //  alert(currentMonday);

    for (let i = 0; i < numWeeks; i++) {
      mondays.push(currentMonday.clone().add(i * 7, "days"));
    }

    return mondays;
  };

  // Generate an array of Mondays for the next 'n' weeks
  const mondays = getMondays(100); // Adjust the number of weeks if needed
  const startOfWeekInitial = moment().startOf("isoWeek").format("YYYY-MM-DD"); // Week starts on Monday
  const currentMonday = moment().startOf("week").add(1, "days"); // Get current week's Monday
  const currentWeekStartDate = currentMonday.format("YYYY-MM-DD"); // Start date
  const currentWeekStartDateipadView = currentMonday.format("MM/DD");
  const currentWeekEndDate = currentMonday
    .clone()
    .add(6, "days")
    .format("YYYY-MM-DD"); // End date
  const currentWeekEndDateipadView = currentMonday
    .clone()
    .add(6, "days")
    .format("MM/DD"); // End date

  console.log(">>Start", currentWeekStartDate);
  console.log(">>End", currentWeekEndDate);

  // Find the index of the current week's Monday in the mondays array
  const currentMondayIndex = mondays.findIndex(
    (monday) => monday.format("YYYY-MM-DD") === currentWeekStartDate
  );

  // setCurrentWeekIndex(currentMondayIndex);

  // Define `dateRange` with initial values as today's week range
  // const [dateRange, setDateRange] = useState({
  //   startDate: moment().startOf("week").add(1, "days").format("YYYY-MM-DD"),
  //   endDate: moment().endOf("week").add(1, "days").format("YYYY-MM-DD"),
  // });

  const [dateRange, setDateRange] = useState({
    startDate:
      moment().day() === 0
        ? moment().startOf("week").subtract(6, "days").format("YYYY-MM-DD") // Current week's Monday if today is Sunday
        : moment().startOf("week").add(1, "days").format("YYYY-MM-DD"), // Normal Monday otherwise
    endDate: moment().endOf("week").add(1, "days").format("YYYY-MM-DD"), // Always next Monday for endDate
  });


  function isNextWeek(date) {
    const today = new Date();
    const currentDay = today.getDay(); // 0 (Sunday) to 6 (Saturday)

    // Get Monday of the current week
    const startOfWeek = new Date(today);
    startOfWeek.setDate(today.getDate() - (currentDay === 0 ? 6 : currentDay - 1)); // Adjust if today is Sunday

    // Get Monday of next week
    const startOfNextWeek = new Date(startOfWeek);
    startOfNextWeek.setDate(startOfWeek.getDate() + 7);

    // Get Sunday of next week
    const endOfNextWeek = new Date(startOfNextWeek);
    endOfNextWeek.setDate(startOfNextWeek.getDate() + 6);

    // Convert input date to Date object
    const checkDate = new Date(date);

    // Ensure only the date (without time) is compared
    startOfNextWeek.setHours(0, 0, 0, 0);
    endOfNextWeek.setHours(23, 59, 59, 999);
    checkDate.setHours(0, 0, 0, 0);

    return checkDate >= startOfNextWeek && checkDate <= endOfNextWeek;
  }


const [isnextweekflag,setIsNextWeekFlag]=useState(false);

  const handleMondayChange = (newMonday) => {
    const startDate = newMonday.format("YYYY-MM-DD");
    const endDate = newMonday.clone().add(6, "days").format("YYYY-MM-DD");

    // mondays = getMondays(selectedMonday, 1);
    setDateRange({ startDate, endDate }); // Update state with the new date range

    var allElements = document.querySelectorAll(".acciveThead"); // Select all elements with 'acciveThead' class
    allElements.forEach((el) => {
      el.classList.remove("acciveThead"); // Remove class from other elements
    });

    var allElementsslide = document.querySelectorAll(".shifViewMain");
    allElementsslide.forEach((el) => {
      el.classList.remove("showShifViewSt");
    });

    

    setIsNextWeekFlag(isNextWeek(startDate));

  

    getIterationCountFunc(startDate,endDate);
    getWeeklyEmployeeStatusConsolidatedView("", startDate, endDate);
    openShifViewDetail(startDate, endDate);
    //  openShifViewDetail(startDate,endDate);
    
  };

  const daysArray = [];
  // Define start and end dates
  const startDate = dateRange.startDate;
  const endDate = dateRange.endDate;

  // Loop through dates from start to end
  const start = moment(startDate, "YYYY-MM-DD"); // Ensure the format is correct
  const end = moment(endDate, "YYYY-MM-DD"); // Ensure the format is correct

  // Create an array with formatted dates
  for (let m = start.clone(); m.isSameOrBefore(end); m.add(1, "day")) {
    // Adjusting the day of the week: moment returns 0 (Sunday) to 6 (Saturday),
    // but we want Monday as 1, and Sunday as 7
    let dayOfWeek = m.format("d"); // Day of the week number (0 = Sunday, 1 = Monday, etc.)
    dayOfWeek = dayOfWeek === "0" ? "7" : dayOfWeek; // Make Sunday '7'

    const formattedDay = m.format("MM/DD"); // Date in MM/DD format
    const formattedDayFull = m.format("YYYY-MM-DD"); // Date in MM/DD format
    const formattedDayOfWeek = m.format("ddd"); // Day of the week abbreviation (Mon, Tue, Wed, etc.)
    const formattedFullDayOfWeek = m.format("dddd");
    // Push the formatted values to the array
    daysArray.push({
      dayOfWeek: dayOfWeek,
      formattedDate: formattedDay,
      formattedDayOfWeek,
      formattedDayFull,
      formattedFullDayOfWeek,
    });
  }

  console.log(daysArray);

  const [slotdetailview, setSlotDetailView] = useState([]);
  const [slotdetailview2, setSlotDetailView2] = useState([]);
  const [slotdetailview3, setSlotDetailView3] = useState([]);
  const [slotfilled, setSlotFilled] = useState(false);
  const [slotdetailviewmessage,setSlotDetailViewMessage]=useState("Please wait...");

  const fetchShiftData = async (url, bodyFormData, isDump = false) => {
    try {
      const response = await makePostRequest(url, bodyFormData);
      if (response.data) {
        return isDump ? response.data.data : response.data.data;
      }
      return null;
    } catch (error) {
      console.error(`Error in ${url}:`, error);
      return null;
    }
  };

  const openShifViewDetail = async (startDate, endDate) => {
    setShowLoader(true);
    let iteration=false;
    const startOfWeek = moment().startOf("isoWeek").format("YYYY-MM-DD"); // Week starts on Monday
    let shiftData = null;

    if (startDate <= startOfWeek) {
      let bodyFormData = {
        apiname: "getWeeklyShiftStatusGrouped",
        startofweek: startOfWeek,
      };

      shiftData = await fetchData(
        "schedular/getDumpDataFromScheduler",
        bodyFormData,
        true
      );

      if (!shiftData) {
        // If no data in dump, fetch from the grouped API
        bodyFormData = {
          startDate: startDate,
          endDate: endDate,
        };

        if(isnextweekflag && !assingedflag)
        {
          iteration=true;
          shiftData = await fetchShiftData(
            "schedular/getWeeklyShiftStatusGroupedIterations",
            bodyFormData
          );
        }
        else
        {
          iteration=false;
          shiftData = await fetchShiftData(
            "schedular/getWeeklyShiftStatusGrouped",
            bodyFormData
          );
        }
        
      }
    } else {
      let bodyFormData = {
        startDate: startDate,
        endDate: endDate,
      };
      if(isnextweekflag && !assingedflag)
        {
          iteration=true;
          shiftData = await fetchShiftData(
            "schedular/getWeeklyShiftStatusGroupedIterations",
            bodyFormData
          );
        }
        else
        {
          iteration=false;
          shiftData = await fetchShiftData(
            "schedular/getWeeklyShiftStatusGrouped",
            bodyFormData
          );
        }
    }

    if (shiftData) {
      console.log("status grouped >> ", shiftData);
      setSlotFilled(shiftData.is_all_filled);
      if(iteration==false)
      {
      setSlotDetailView(shiftData.schedule);
      }
      else
      {
        if (Array.isArray(shiftData) && shiftData.length > 0) {
         
          setSlotDetailView(shiftData[0].locations);

          if(shiftData.length==2)
            setSlotDetailView2(shiftData[1].locations);
          else
            setSlotDetailView2([])

          if(shiftData.length==3)
          {
            setSlotDetailView2(shiftData[1].locations);
            setSlotDetailView3(shiftData[2].locations);
          } 
          else
          {
          
            setSlotDetailView3([]);
          }
         

          
        } else {
          console.warn("Error: shiftData is missing or empty", shiftData);
          setSlotDetailViewMessage("No Data Found");
          setSlotDetailView([]);
          setSlotDetailView2([]);
          setSlotDetailView3([]);
        }
      }
      // setChangeDetected(false);
    } else {
      setSlotDetailView([]);
      setSlotFilled(false);
      // setChangeDetected(false);
    }

    setShowLoader(false);
  };

  useEffect(() => {
    // getStartWeekdate();
    // openShifViewDetail(dateRange.startDate,
    //   dateRange.endDate);
    getAllEmployeeList(null);

    openShifViewDetail(dateRange.startDate, dateRange.endDate);
    getWeeklyEmployeeStatusConsolidatedView(
      "",
      dateRange.startDate,
      dateRange.endDate
    );
    getSwapOfferRequestsAdmin();
   
    // intializeSliderDates();
  }, []);

  // useEffect(() => {
  //   console.log("slotdetailview2 updated:", slotdetailview2);
  // }, [slotdetailview2]); // Ensure component reacts to changes

  const [hiddenRows, setHiddenRows] = React.useState([]); // Track hidden rows
  const handleHideRow = async (employeeId, location) => {
    let obj = {
      weekdate: dateRange.startDate,
      location: location,
      emp_id: employeeId,
    };

    await makePostRequest("employee/updateOrInsertEmployeeHide", obj)
      .then((response) => {
        getWeeklyEmployeeStatusConsolidatedView(
          "",
          dateRange.startDate,
          dateRange.endDate
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleUnhideAllRows = async (location) => {
    let obj = {
      weekdate: dateRange.startDate,
      location: location,
    };

    await makePostRequest("employee/unhideEmployee", obj)
      .then((response) => {
        getWeeklyEmployeeStatusConsolidatedView(
          "",
          dateRange.startDate,
          dateRange.endDate
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

   // Show button ONLY if today is NOT the start date
   console.log(moment().startOf("day").format('YYYY-MM-DD'));
   console.log(dateRange.startDate);
   const showButton = moment().startOf("day").format('YYYY-MM-DD') !== dateRange.startDate;
  return (
    <>
      <div className="prefranOrAvailab">
        <div className="prefLeft">
          <h1>View employee preferences</h1>
          <div className="prefranOrAvailabInr">
            <p onClick={() => navigate("/Preference")}>
              Preferences <ArrowForwardIcon />
            </p>
            {/* <p onClick={() => navigate("/Availability")}>
                              Availability <ArrowForwardIcon />
                            </p> */}
          </div>
        </div>
        <span
          className={`publishIcon ${scrolling ? "addedPublish" : ""}`}
          style={{
            pointerEvents: slotfilled ? "auto" : "none",
            opacity: slotfilled ? 1 : 0.5,
          }}
          onClick={slotfilled ? handlePublish : undefined}
        >
          <img src={publishIco} />
        </span>
      </div>

      <div className="forTabletView">
        <div className="wrapper innerPagesMain" style={{ paddingBottom: 0 }}>
          {/* TopBar */}
          <div className="innerPageTopSecBackPatch"></div>
          <div className="innerPageTopSec">
            <div className="forBackArrow">
              <NavigateBeforeIcon onClick={() => navigate(-1)} />
            </div>
            <h1>Preview</h1>
            <div className="forBackArrow"></div>
          </div>
          {/* TopBarEnd */}
        </div>

        <div className="schedlPreviewMain">
          {/* <h1 class="shiftHeadinInTab">Summer Shifts</h1> */}
          <Grid item xs={12} md={12}>
            <div className="mySlik createScheduSlik forTabletSliderOnly">
              <div className="slider-container">
                <Slider
                  {...settings2}
                  initialSlide={currentweekindex} // Set the initial slide to current Monday
                  afterChange={(index) => handleMondayChange(mondays[index])}
                >
                  {mondays.map((monday, index) => (
                    <div key={index}>
                      <h3>Mon</h3>
                      <h3 className="currentWeekTx">Current Week</h3>
                      <span className="forTabletDate">
                        {monday.format("MM/DD")} -{" "}
                        {monday.clone().add(6, "days").format("MM/DD")}
                      </span>
                      <span className="forMobileDate">
                        {monday.format("MM/DD/YYYY")}
                      </span>{" "}
                      {/* Format the date as desired */}
                    </div>
                  ))}
                </Slider>
              </div>
            </div>
          </Grid>

          <div className="forNotificationMain" style={{ marginBottom: 0 }}>
            <Slider {...settings}>
              {request.length > 0 &&
                request.map((req, index) => (
                  <div
                    className="forNotiTablet"
                    style={{
                      marginBottom: 0,
                      width: "100%",
                      float: "left",
                    }}
                  >
                    <div className="notificationMain">
                      {req.adminid != 0 && req.adminaction != null && (
                        <CloseIcon className="statusCls" />
                      )}
                      <div
                        className="notificationTp"
                        style={{ marginBottom: 9 }}
                      >
                        <div className="notificationProp">
                          <img src={picAv} />
                        </div>
                        {req.request_type == "swap" ? (
                          <div>
                            <h1>
                              {req.full_name}{" "}
                              <span>
                                has a swap request accepted by {req.tofull_name}
                                . Below are the details:
                              </span>
                            </h1>
                            <div className="swapDataMain">
                              <div
                                className="swapDatTop wantToSwapMain swpDtNew"
                                style={{ paddingTop: 15 }}
                              >
                                <label className="notifLocation">
                                  {req.sourcelocation}
                                </label>
                                <div className="swapDatInr">
                                  <div className="assignShiProLine">
                                    <div className="swapShiRight">
                                      <h1>
                                        {req.sourceweekday} {req.sourceweekdate}
                                      </h1>
                                      <p>{req.sourceshifttime}</p>
                                    </div>
                                  </div>
                                  <SwapHorizontalCircleIcon className="swpIc" />
                                </div>
                                <div className="swapDatInr">
                                  <div className="assignShiProLine">
                                    <div className="swapShiRight">
                                      <h1>
                                        {req.destweekday} {req.destweekdate}
                                      </h1>
                                      <p>{req.destshifttime}</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <h1>
                            {req.full_name}{" "}
                            <span>
                              send an {req.request_type} request, accepted by{" "}
                              <b>{req.tofull_name}</b>. Below are the details:
                            </span>
                          </h1>
                        )}
                      </div>
                      {req.request_type === "offer" && (
                        <div className="swapDataMain">
                          <div
                            className="swapDatTop wantToSwapMain swpDtNew"
                            style={{ paddingTop: 15 }}
                          >
                            <label className="notifLocation">
                              {req.sourcelocation}
                            </label>
                            <div className="swapDatInr">
                              <div className="assignShiProLine forSingleShift">
                                <div className="swapShiRight">
                                  <h1>
                                    <CalendarMonthIcon /> {req.sourceweekday}{" "}
                                    {req.sourceweekdate}
                                  </h1>
                                  <p>
                                    <AccessTimeIcon /> {req.sourceshifttime}
                                  </p>
                                </div>
                              </div>

                              {/* <SwapHorizontalCircleIcon className="swpIc" /> */}
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="rejNAcepBts">
                        <Button
                          onClick={() =>
                            requestSwapActionAdmin(req.empswapofferid, false)
                          }
                        >
                          Refuse
                        </Button>
                        <Button
                          onClick={() =>
                            requestSwapActionAdmin(req.empswapofferid, true)
                          }
                          style={{ color: "#0FB000" }}
                        >
                          Approve
                        </Button>
                      </div>
                    </div>
                  </div>
                ))}
            </Slider>
          </div>
          
          {/* <Draggable> */}
  <div> {/* ✅ Ensures Draggable always has a valid child */}
    {(!iterationreached) ? 
    !assingedflag && isnextweekflag && (
      <Button className="floating-btn" name="autoscript" onClick={()=>handleAutoScript()}>
        Run AutoScript <span className="scriptCount">{iterationcount}</span>
      </Button>
   ): null} 
  </div>
{/* </Draggable> */}

                   

          <div className={`tblSwitch ${scrolling ? "toggleTblSwitch" : ""}`}>
            <>
              <Switch {...label} onClick={toggleTbl} />
            </>
          </div>
          {loc.length > 0 &&
            loc
              .filter((item) => item !== "No Location")
              .map((item, index) => (
                <div className="daySlider emplShifSlider schedPreviewSlideMain">
                  <h5
                    className={`stickHeading ${topclass}`}
                    // style={{ marginTop: 15 }}
                  >
                    <span className="tableHiddenShowArr">
                      <CompressIcon onClick={() => handleUnhideAllRows(item)} />
                    </span>
                    {/* <div className="tblSwitch">
                    <Switch {...label} onClick={toggleTbl} />
                  </div> */}
                    <h3 class="newPreviewLabelText">
                      {" "}
                      {label} {item}
                    </h3>
                    {/* {item} */}
                    <span
                      onClick={openBottMainClick}
                      className={`tableHiddenShowWeek ${
                        scrolling2 ? "moveWeekIco" : ""
                      }`}
                    >
                      <CalendarMonthIcon />
                    </span>
                  </h5>



                  <div
                    className="shifViewMain shifViewMainForDay"
                    id={`showShifViewMain${item}`}
                  >
                    {slotdetails.length > 0 &&
                      slotdetails.map((slot, index) => (
                        <>
                          <h1
                            className={
                              slot.hasOwnProperty("shiftadded") ? "localBg" : ""
                            }
                          >
                            <label className="indicInTblTh">
                              {slot.frequency_name}
                            </label>
                            {slot.shifttime}{" "}
                            <em
                              className={
                                slot.pending_emp_count == 0
                                  ? "activeFullFill"
                                  : ""
                              }
                            >
                              <GroupOutlinedIcon /> {slot.required_emp_count}
                              {slot.pending_emp_count != 0 ? (
                                <span className="pendingEmp">
                                  +{slot.pending_emp_count}
                                </span>
                              ) : null}
                            </em>
                            {dateRange.startDate <= startOfWeekInitial && (
                              <IconButton
                                aria-label="edit"
                                onClick={() =>
                                  handleClickOpen(slot, "edit", item)
                                }
                              >
                                <img src={editwhite} />
                              </IconButton>
                            )}
                          </h1>
                        </>
                      ))}
                    {dateRange.startDate <= startOfWeekInitial && (
                      <h1
                        aria-label="edit"
                        onClick={() => handleClickOpen("", "add", item)}
                      >
                        Add New Shift
                        <AddIcon />
                      </h1>
                    )}
                  </div>

                  <div className="schedulPreviewTblMain" id={`target${index}`}>
                    {/* id={'target'+index} */}
                    <div
                      className="tblSearchEmp"
                      key={index}
                      id={`searchSlideId${index}`}
                    >
                      <SearchIcon />
                      <input
                        type="text"
                        placeholder="Search"
                        onChange={(e) => searchEmp(e, item)}
                      />
                    </div>

                    <div
                      class="table-wrapper"
                      key={index}
                      id={`hideShowTbls${index}`}
                    >
                      <table width="100%" className="onlyPreviewTbl">
                        <thead>
                          <tr>
                            <th class="fixed-column" style={{ width: 95 }}>
                              <div
                                className="tblSearchIcoOt searchEmpTx"
                                onClick={() => searchSlide(index, item)}
                              >
                                <PersonSearchIcon /> <span>Search Emp</span>
                              </div>
                            </th>
                            {daysArray.map((day, index) => (
                              <th
                                key={index}
                                id={`${item}_${index}`}
                                onClick={() => openShifView(item, index, day)}
                                className="forShiftsIndicat"
                              >
                                <p>
                                  <span>{day.formattedDayOfWeek}</span>{" "}
                                  <span>{day.formattedDate}</span>
                                </p>
                                {/* <b className="actFullFill"> */}
                                {/* <em></em> {slotdetailview.item.weekdays[day.formattedFullDayOfWeek].shifts.length} Shifts */}

                                {slotdetailview.map((slotItem) => {
                                  if (slotItem.location === item) {
                                    // Check if location matches
                                    const weekdayData = slotItem.weekdays.find(
                                      (weekday) =>
                                        weekday.weekday ===
                                        day.formattedFullDayOfWeek
                                    );

                                    // If weekday data exists, fetch the number of shifts
                                    const shiftCount = weekdayData
                                      ? weekdayData.shifts.length
                                      : 0;
                                    const pendingflag = weekdayData
                                      ? weekdayData.shifts.some(
                                          (shift) => shift.pending_emp_count > 0
                                        )
                                      : false;

                                    return (
                                      <div
                                        key={`${item.location}-${day.formattedFullDayOfWeek}`}
                                      >
                                        <b
                                          className={
                                            pendingflag
                                              ? "actUnAvailFill"
                                              : "actFullFill"
                                          }
                                        >
                                          <em></em> {shiftCount} Shifts
                                        </b>
                                      </div>
                                    );
                                  }
                                  return null;
                                })}
                                {/* </b> */}
                              </th>
                            ))}
                          </tr>
                        </thead>

                        <tbody>
                          {viewdata[item].length > 0 &&
                            viewdata[item].map((empinfo, index) => (
                              <React.Fragment key={empinfo.employee_id}>
                                <tr>
                                  {/* Column for "Hide Row" Button */}
                                  <td
                                    className="fixed-column"
                                    style={{ paddingLeft: 33 }}
                                  >
                                    <p className="dayNDateCol">
                                      <h1>{empinfo.full_name}</h1>
                                    </p>

                                    {/* ↑ Hidden Row Above (Click to Restore) ↑ */}
                                    <RemoveCircleIcon
                                      className="showHiddenRowArrow"
                                      onClick={() =>
                                        handleHideRow(empinfo.employee_id, item)
                                      }
                                    />
                                  </td>

                                  {/* Weekly Status Columns */}
                                  {empinfo.weekly_status.length > 0 &&
                                    empinfo.weekly_status.map(
                                      (week, indexweek) => (
                                        <>
                                          {week.status !== "SB" ? (
                                            <td>
                                              <div
                                                className="swapEmpDiv"
                                                onClick={() =>
                                                  handleClickChangeShift({
                                                    id: empinfo.employee_id,
                                                    name: empinfo.full_name,
                                                    profile_pic:
                                                      empinfo.profile_pic,
                                                    location: item,
                                                    weekday: week.weekday,
                                                    weekdate: week.weekdate,
                                                    weekstatus: week.status,
                                                  })
                                                }
                                              >
                                                <h1
                                                  className={
                                                    week.status === "A"
                                                      ? "AssignedStatus"
                                                      : "UnassignedStatus"
                                                  }
                                                >
                                                  {week.status}
                                                  {/* <p>navailable</p> */}
                                                </h1>
                                                {week.assigned_shift_times.map(
                                                  (shift, indexshift) => (
                                                    <span
                                                      key={`assigned-${indexshift}`}
                                                    >
                                                      {shift}
                                                    </span>
                                                  )
                                                )}
                                                {/* {week.standby_shift_times.map(
                                                (
                                                  shiftavail,
                                                  indexshiftavail
                                                ) => (
                                                  <span
                                                    className="newStandbySpan"
                                                    key={`standby-${indexshiftavail}`}
                                                  >
                                                    {shiftavail}
                                                  </span>
                                                )
                                              )} */}
                                              </div>
                                            </td>
                                          ) : (
                                            <td>
                                              <div
                                                className="swapEmpDiv"
                                                onClick={() =>
                                                  handleClickAssignShift({
                                                    id: empinfo.employee_id,
                                                    name: empinfo.full_name,
                                                    profile_pic:
                                                      empinfo.profile_pic,
                                                    location: item,
                                                    weekday: week.weekday,
                                                    weekdate: week.weekdate,
                                                  })
                                                }
                                              >
                                                <h1 className="standbyHeadColor">
                                                  {week.status}
                                                </h1>
                                                <AddCircleIcon className="sbIcon" />
                                              </div>
                                            </td>
                                          )}
                                        </>
                                      )
                                    )}
                                </tr>
                              </React.Fragment>
                            ))}
                        </tbody>
                      </table>

                      <table width="100%" className="DetailPreviewTbl">
                        <thead>
                          <tr>
                            <th class="fixed-column" style={{ width: 95 }}>
                              <div
                                className="tblSearchIcoOt"
                                onClick={() => searchSlide(index, item)}
                              >
                                <PersonSearchIcon />
                              </div>
                            </th>
                            {daysArray.map((day, index) => (
                              <th
                                key={index}
                                id={`${item}_${index}`}
                                onClick={() => openShifView(item, index, day)}
                                className="forShiftsIndicat"
                              >
                                <p>
                                  <span>{day.formattedDayOfWeek}</span>{" "}
                                  <span>{day.formattedDate}</span>
                                </p>
                                {/* <b className="actFullFill"> */}
                                {/* <em></em> {slotdetailview.item.weekdays[day.formattedFullDayOfWeek].shifts.length} Shifts */}

                                {slotdetailview.map((slotItem) => {
                                  if (slotItem.location === item) {
                                    // Check if location matches
                                    const weekdayData = slotItem.weekdays.find(
                                      (weekday) =>
                                        weekday.weekday ===
                                        day.formattedFullDayOfWeek
                                    );

                                    // If weekday data exists, fetch the number of shifts
                                    const shiftCount = weekdayData
                                      ? weekdayData.shifts.length
                                      : 0;
                                    const pendingflag = weekdayData
                                      ? weekdayData.shifts.some(
                                          (shift) => shift.pending_emp_count > 0
                                        )
                                      : false;

                                    return (
                                      <div
                                        key={`${item.location}-${day.formattedFullDayOfWeek}`}
                                      >
                                        <b
                                          className={
                                            pendingflag
                                              ? "actUnAvailFill"
                                              : "actFullFill"
                                          }
                                        >
                                          <em></em> {shiftCount} Shifts
                                        </b>
                                      </div>
                                    );
                                  }
                                  return null;
                                })}
                                {/* </b> */}
                              </th>
                            ))}
                          </tr>
                        </thead>

                        <tbody>
                          {viewdata[item].length > 0 &&
                            viewdata[item].map((empinfo, index) => (
                              <React.Fragment key={empinfo.employee_id}>
                                {/* Check if the row is hidden */}

                                <tr>
                                  <td
                                    className="fixed-column"
                                    style={{ paddingLeft: 30 }}
                                  >
                                    <p className="dayNDateCol">
                                      <h1>{empinfo.full_name}</h1>
                                    </p>
                                    {/* Button to hide the row */}
                                    {/* ↑ Hidden Row Above (Click to Restore) ↑ */}
                                    <RemoveCircleIcon
                                      className="showHiddenRowArrow"
                                      onClick={() =>
                                        handleHideRow(empinfo.employee_id, item)
                                      }
                                    />
                                  </td>
                                  {empinfo.weekly_status.length > 0 &&
                                    empinfo.weekly_status.map(
                                      (week, indexweek) => (
                                        <React.Fragment key={indexweek}>
                                          {week.status !== "SB" ? (
                                            <td>
                                              <div
                                                className="swapEmpDiv"
                                                onClick={() =>
                                                  handleClickChangeShift({
                                                    id: empinfo.employee_id,
                                                    name: empinfo.full_name,
                                                    profile_pic:
                                                      empinfo.profile_pic,
                                                    location: item,
                                                    weekday: week.weekday,
                                                    weekdate: week.weekdate,
                                                    weekstatus: week.status,
                                                  })
                                                }
                                              >
                                                <h1
                                                  className={
                                                    week.status === "A"
                                                      ? "AssignedStatus"
                                                      : "UnassignedStatus"
                                                  }
                                                >
                                                  {week.status}
                                                </h1>

                                                {/* Assigned Shifts */}
                                                {week.assigned_shift_times
                                                  .length > 0 &&
                                                  week.assigned_shift_times.map(
                                                    (shift, indexshift) => (
                                                      <span
                                                        className="newAssignSpan"
                                                        key={indexshift}
                                                      >
                                                        {shift}
                                                      </span>
                                                    )
                                                  )}

                                                {/* Standby Shifts */}
                                                {week.standby_shift_times
                                                  .length > 0 &&
                                                  week.standby_shift_times
                                                    .filter(
                                                      (shiftavail) =>
                                                        !week.assigned_shift_times.includes(
                                                          shiftavail
                                                        )
                                                    )
                                                    .map(
                                                      (
                                                        shiftavail,
                                                        indexshiftavail
                                                      ) => (
                                                        <span
                                                          className="newStandbySpan"
                                                          key={indexshiftavail}
                                                        >
                                                          {shiftavail}
                                                        </span>
                                                      )
                                                    )}
                                              </div>
                                            </td>
                                          ) : (
                                            <td>
                                              <div
                                                className="swapEmpDiv"
                                                onClick={() =>
                                                  handleClickAssignShift({
                                                    id: empinfo.employee_id,
                                                    name: empinfo.full_name,
                                                    profile_pic:
                                                      empinfo.profile_pic,
                                                    location: item,
                                                    weekday: week.weekday,
                                                    weekdate: week.weekdate,
                                                  })
                                                }
                                              >
                                                <h1 className="standbyHeadColor">
                                                  {week.status}
                                                </h1>
                                                {week.standby_shift_times
                                                  .length > 0 &&
                                                  week.standby_shift_times.map(
                                                    (
                                                      shiftavail,
                                                      indexshiftavail
                                                    ) => (
                                                      <div className="newSBModColor">
                                                        <span
                                                        className="sbMod"
                                                        key={indexshiftavail}
                                                      >
                                                        {shiftavail}
                                                      </span>
                                                        </div>
                                                    )
                                                  )}
                                                <AddCircleIcon className="sbIcon" />
                                              </div>
                                            </td>
                                          )}
                                        </React.Fragment>
                                      )
                                    )}
                                </tr>
                              </React.Fragment>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              ))}
          {showLoader ? (
            <div className="loaderNew">
              <div className="loader"></div>
            </div>
          ) : (
            <></>
          )}
        </div>

        <div className="bottViewMain" id="openBottMainId" style={{paddingTop:55}}>
        {isnextweekflag ? !assingedflag && ( <Button className="skipBtn" onClick={()=>confirmIgnoreAction("Ignore",0) }><EventBusyIcon/> Ignore</Button>): null}
          <h5 className="bottViewMainHeeadding">
            <b>
              {dateRange.startDate} <ArrowRightAltIcon /> {dateRange.endDate}
            </b>
          </h5>
          <CloseIcon
            className="bottViewMainClose"
            onClick={openBottMainClick}
          />

          <div className="attemTabMain">
            <Box sx={{ width: '100%' }}>
              {isnextweekflag ? !assingedflag && ( <Box>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                  <Tab label="Schedule 1" {...a11yProps(0)}   />
                   <Tab 
                  label="Schedule 2" 
                  {...a11yProps(1)} 
                  disabled={slotdetailview2.length == 0} 
                  />
                 <Tab 
                    label="Schedule 3" 
                    {...a11yProps(2)} 
                    disabled={slotdetailview3.length == 0} 
                  />
                </Tabs>
              </Box> ): null}
              <CustomTabPanel value={value} index={0}>
                <div className="attemTabCont">
                  <div className="shiftOpenContScroll">
                    {slotdetailview.length > 0 ? (
                      slotdetailview.map((slotview, index) => (
                        <div className="shiftOpenCont" key={index}>
                          <h1>
                            <LocationOnIcon /> {slotview.location}{" "}
                            {/* Display location name */}
                          </h1>
                          {slotview.weekdays.map((item, idx) => (
                            <div className="weekShowFull" key={idx}>
                              <label>
                                <div>
                                  {item.weekday}{" "}
                                  <span>({item.shifts.length} Shifts)</span>
                                </div>
                                {/* <em>01/13/2025</em> */}
                              </label>
                              <div className="weekShowFullInr">
                                {item.shifts.length > 0 &&
                                  item.shifts.map((shift, shiftindex) => (
                                    <>
                                      <div
                                        className={
                                          shift.hasOwnProperty("shiftadded")
                                            ? "withEmpName localBg2"
                                            : "withEmpName"
                                        }
                                      >
                                        <label className="locLabSt">
                                          {shift.frequency_name}
                                        </label>
                                        <h3 key={shiftindex}>
                                          {shift.shifttime}{" "}
                                          <em
                                            className={
                                              shift.pending_emp_count === 0
                                                ? "activeFullFill"
                                                : null
                                            }
                                          >
                                            <GroupOutlinedIcon />{" "}
                                            {shift.required_emp_count}
                                            {shift.pending_emp_count !== 0 && (
                                              <span className="pendingEmp">
                                                +{shift.pending_emp_count}
                                              </span>
                                            )}
                                          </em>
                                        </h3>
                                        {shift.assigned_employees.length > 0 &&
                                          shift.assigned_employees.map(
                                            (empinfo, index) => (
                                              <p>{empinfo.emp_name}</p>
                                            )
                                          )}
                                      </div>
                                    </>
                                  ))}
                              </div>
                            </div>
                          ))}
                        </div>
                      ))
                    ) : (
                      <p>{slotdetailviewmessage}</p>
                    )}
                  </div>
               {(isnextweekflag && slotdetailviewmessage=="Please wait...") ? !assingedflag &&  <Button className="schedulConfirm" onClick={()=>confirmIgnoreAction("Confirm",1)}>Done</Button>: null}
                </div>
              </CustomTabPanel>
              <CustomTabPanel value={value} index={1}>
              <div className="attemTabCont">
                  <div className="shiftOpenContScroll">
                    {slotdetailview2.length > 0 ? (
                      slotdetailview2.map((slotview, index) => (
                        <div className="shiftOpenCont" key={index}>
                          <h1>
                            <LocationOnIcon /> {slotview.location}{" "}
                            {/* Display location name */}
                          </h1>
                          {slotview.weekdays.map((item, idx) => (
                            <div className="weekShowFull" key={idx}>
                              <label>
                                <div>
                                  {item.weekday}{" "}
                                  <span>({item.shifts.length} Shifts)</span>
                                </div>
                                {/* <em>01/13/2025</em> */}
                              </label>
                              <div className="weekShowFullInr">
                                {item.shifts.length > 0 &&
                                  item.shifts.map((shift, shiftindex) => (
                                    <>
                                      <div
                                        className={
                                          shift.hasOwnProperty("shiftadded")
                                            ? "withEmpName localBg2"
                                            : "withEmpName"
                                        }
                                      >
                                        <label className="locLabSt">
                                          {shift.frequency_name}
                                        </label>
                                        <h3 key={shiftindex}>
                                          {shift.shifttime}{" "}
                                          <em
                                            className={
                                              shift.pending_emp_count === 0
                                                ? "activeFullFill"
                                                : null
                                            }
                                          >
                                            <GroupOutlinedIcon />{" "}
                                            {shift.required_emp_count}
                                            {shift.pending_emp_count !== 0 && (
                                              <span className="pendingEmp">
                                                +{shift.pending_emp_count}
                                              </span>
                                            )}
                                          </em>
                                        </h3>
                                        {shift.assigned_employees.length > 0 &&
                                          shift.assigned_employees.map(
                                            (empinfo, index) => (
                                              <p>{empinfo.emp_name}</p>
                                            )
                                          )}
                                      </div>
                                    </>
                                  ))}
                              </div>
                            </div>
                          ))}
                        </div>
                      ))
                    ) : (
                      <p>{slotdetailviewmessage}</p>
                    )}
                  </div>
                  {(isnextweekflag &&  slotdetailviewmessage=="Please wait...") ? !assingedflag &&   <Button className="schedulConfirm" onClick={()=>confirmIgnoreAction("Confirm",2)}>Done</Button>:null}
                </div>
              </CustomTabPanel>
              <CustomTabPanel value={value} index={2}>
              <div className="attemTabCont">
                  <div className="shiftOpenContScroll">
                    {slotdetailview3.length > 0 ? (
                      slotdetailview3.map((slotview, index) => (
                        <div className="shiftOpenCont" key={index}>
                          <h1>
                            <LocationOnIcon /> {slotview.location}{" "}
                            {/* Display location name */}
                          </h1>
                          {slotview.weekdays.map((item, idx) => (
                            <div className="weekShowFull" key={idx}>
                              <label>
                                <div>
                                  {item.weekday}{" "}
                                  <span>({item.shifts.length} Shifts)</span>
                                </div>
                                {/* <em>01/13/2025</em> */}
                              </label>
                              <div className="weekShowFullInr">
                                {item.shifts.length > 0 &&
                                  item.shifts.map((shift, shiftindex) => (
                                    <>
                                      <div
                                        className={
                                          shift.hasOwnProperty("shiftadded")
                                            ? "withEmpName localBg2"
                                            : "withEmpName"
                                        }
                                      >
                                        <label className="locLabSt">
                                          {shift.frequency_name}
                                        </label>
                                        <h3 key={shiftindex}>
                                          {shift.shifttime}{" "}
                                          <em
                                            className={
                                              shift.pending_emp_count === 0
                                                ? "activeFullFill"
                                                : null
                                            }
                                          >
                                            <GroupOutlinedIcon />{" "}
                                            {shift.required_emp_count}
                                            {shift.pending_emp_count !== 0 && (
                                              <span className="pendingEmp">
                                                +{shift.pending_emp_count}
                                              </span>
                                            )}
                                          </em>
                                        </h3>
                                        {shift.assigned_employees.length > 0 &&
                                          shift.assigned_employees.map(
                                            (empinfo, index) => (
                                              <p>{empinfo.emp_name}</p>
                                            )
                                          )}
                                      </div>
                                    </>
                                  ))}
                              </div>
                            </div>
                          ))}
                        </div>
                      ))
                    ) : (
                      <p>{slotdetailviewmessage}</p>
                    )}
                  </div>
                  {(isnextweekflag &&  slotdetailviewmessage=="Please wait...")? !assingedflag &&   <Button className="schedulConfirm" onClick={()=>confirmIgnoreAction("Confirm",3)}>Done</Button>:null}
                </div>
              </CustomTabPanel>
            </Box>
          </div>
        </div>
      </div>

      {/* Assign Shift Moddal */}
      <div className="">
        <React.Fragment>
          <Dialog
            open={AssignShift}
            onClose={handleCloseAssignShift}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="myAppModalMain forBeforAfterMod"
          >
            <DialogTitle
              id="alert-dialog-title"
              style={{ background: "#f6f6f6" }}
            >
              <h1
                className="modalHeading"
                style={{ marginTop: 0, paddingTop: 25 }}
              >
                <h6 className="extrHeading">Assign Shift</h6>
                Assign shift to <b style={{ color: "#990000" }}>
                  {sbobj.name}
                </b>{" "}
                for the
                <br />
                <b className="alrdLoc">
                  {sbobj.location} on {sbobj.weekday}{" "}
                  <span>{sbobj.weekdate}</span>
                </b>
              </h1>
              <IconButton
                className="modalCloseIco"
                onClick={handleCloseAssignShift}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>

            <DialogContent style={{ padding: 0 }}>
              <DialogContentText id="alert-dialog-description">
                <div className="modalBodyCont">
                  <div className="previewScheduModMain">
                    <div className="assignedShiftDivMain pdBottom">
                      <p className="assignTaskP">
                        {/* {sbobj.name!="" && sbobj.name.split(" ")[0].charAt(0).toUpperCase() + sbobj.name.split(" ")[0].slice(1)}'s Availability Shifts Information */}
                        {sbobj.name}'s Availability Shifts Information
                      </p>
                      {sbshiftsinfo.length > 0 &&
                        sbshiftsinfo.map((shifts, index) => (
                          <div className="assignedShiftInr cardStatusSB">
                            <div className="dayWInr">
                              <span>
                                {shifts.shifttime}{" "}
                                {shifts.hasOwnProperty("shiftadded") &&
                                  (shifts.shiftadded == "Local" ||
                                    shifts.shiftadded == "employeeLocal") && (
                                    <em className="localTxt">Local</em>
                                  )}
                              </span>
                              <b>
                                <PeopleOutlineIcon /> {shifts.emp_count}
                              </b>
                            </div>

                            <div className="assignShiProLine">
                              <div className="assignShiProLineInr assigPlac">
                                <div className="assignShiPro">
                                  <img
                                    src={
                                      sbobj.profile_pic
                                        ? sbobj.profile_pic
                                        : picPro
                                    }
                                  />
                                </div>
                                <h1>{sbobj.name}</h1>
                              </div>
                              <div
                                className="relesD"
                                onClick={() =>
                                  assignShiftToSB(
                                    sbobj.name,
                                    sbobj.id,
                                    sbobj.profile_pic,
                                    shifts,
                                    sbobj.weekday,
                                    sbobj.weekdate
                                  )
                                }
                              >
                                <Button className="SiftAssignToBt">
                                  Assign
                                </Button>
                              </div>
                            </div>

                            {shifts.assigned_employee_details.length > 0 ? (
                              <label class="assignedShi">
                                Currently Assigned to Shift
                              </label>
                            ) : (
                              <label class="assignedShi">
                                No Employees Assigned To Shift
                              </label>
                            )}
                            {shifts.assigned_employee_details.length > 0 &&
                              shifts.assigned_employee_details.map(
                                (empinfo, empindex) => (
                                  <div className="assignShiProLine">
                                    <div className="assignShiProLineInr">
                                      <div className="assignShiPro">
                                        <img src={empinfo.profile_pic} />
                                      </div>
                                      <h1>{empinfo.emp_name}</h1>
                                    </div>
                                    <div
                                      className="relesD"
                                      key={empindex}
                                      onClick={() =>
                                        releaseEmp(
                                          sbobj.name,
                                          empinfo.emp_id,
                                          empinfo.emp_name,
                                          sbobj.id,
                                          shifts.shifttime,
                                          sbobj.location,
                                          sbobj.weekday,
                                          sbobj.weekdate
                                        )
                                      }
                                    >
                                      Release{" "}
                                      <CloseIcon className="relesEmpIco" />
                                    </div>
                                  </div>
                                )
                              )}
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </DialogContentText>
              <DialogContentText id="alert-dialog-description">
                <div className="modalBodyCont">
                  <div className="previewScheduModMain">
                    <div className="assignedShiftDivMain">
                      {sbushiftsinfo.length > 0 && (
                        // <p className="assignTaskP">
                        // {sbobj.name!="" && sbobj.name.split(" ")[0].charAt(0).toUpperCase() + sbobj.name.split(" ")[0].slice(1)}'s Unavailability Shifts Information
                        // </p>
                        <p className="assignTaskP">
                          {sbobj.name}'s Unavailability Shifts Information
                        </p>
                      )}
                      {sbushiftsinfo.length > 0 &&
                        sbushiftsinfo.map((shifts, index) => (
                          <div className="assignedShiftInr">
                            <div className="dayWInr">
                              <span>
                                {shifts.shifttime}{" "}
                                <em class="localTxt">Local</em>
                              </span>
                              <b>
                                <PeopleOutlineIcon /> {shifts.emp_count}
                              </b>
                            </div>

                            <div className="assignShiProLine">
                              <div className="assignShiProLineInr assigPlac">
                                <div className="assignShiPro">
                                  <img
                                    src={
                                      sbobj.profile_pic
                                        ? sbobj.profile_pic
                                        : picPro
                                    }
                                  />
                                </div>
                                <h1>{sbobj.name}</h1>
                              </div>
                              <div
                                className="relesD"
                                onClick={() =>
                                  assignShiftToSB(
                                    sbobj.name,
                                    sbobj.id,
                                    sbobj.profile_pic,
                                    shifts,
                                    sbobj.weekday,
                                    sbobj.weekdate
                                  )
                                }
                              >
                                <Button className="SiftAssignToBt">
                                  Assign
                                </Button>
                              </div>
                            </div>

                            {shifts.assigned_employee_details.length > 0 ? (
                              <label class="assignedShi">
                                Currently Assigned to Shift
                              </label>
                            ) : (
                              <label class="assignedShi">
                                No Employees Assigned To Shift
                              </label>
                            )}
                            {shifts.assigned_employee_details.length > 0 &&
                              shifts.assigned_employee_details.map(
                                (empinfo, empindex) => (
                                  <div className="assignShiProLine">
                                    <div className="assignShiProLineInr">
                                      <div className="assignShiPro">
                                        <img src={empinfo.profile_pic} />
                                      </div>
                                      <h1>{empinfo.emp_name}</h1>
                                    </div>
                                    <div
                                      className="relesD"
                                      key={empindex}
                                      onClick={() =>
                                        releaseEmp(
                                          sbobj.name,
                                          empinfo.emp_id,
                                          empinfo.emp_name,
                                          sbobj.id,
                                          shifts.shifttime,
                                          sbobj.location,
                                          sbobj.weekday,
                                          sbobj.weekdate
                                        )
                                      }
                                    >
                                      Release{" "}
                                      <CloseIcon className="relesEmpIco" />
                                    </div>
                                  </div>
                                )
                              )}
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </DialogContentText>
            </DialogContent>

            {/* <DialogContent style={{ padding: 0 }}>
              <DialogContentText id="alert-dialog-description">
                <div className="modalBodyCont">
                  <div className="previewScheduModMain">
                    <div className="assignedShiftDivMain">
                    {sbushiftsinfo.length > 0 && 
                      <p className="assignTaskP">
                      {sbobj.name.split(" ")[0].charAt(0).toUpperCase() + sbobj.name.split(" ")[0].slice(1)}'s Unavailability Shifts Information
                      </p>
                    }
                      {sbushiftsinfo.length > 0 &&
                        sbushiftsinfo.map((shifts, index) => (
                          <div className="assignedShiftInr">
                            <div className="dayWInr">
                              <span>{shifts.shifttime}</span>
                              <b>
                                <PeopleOutlineIcon /> {shifts.emp_count}
                              </b>
                            </div>

                            <div className="assignShiProLine">
                              <div className="assignShiProLineInr assigPlac">
                                <div className="assignShiPro">
                                  <img
                                    src={
                                      sbobj.profile_pic
                                        ? sbobj.profile_pic
                                        : picPro
                                    }
                                  />
                                </div>
                                <h1>{sbobj.name}</h1>
                              </div>
                              <div
                                className="relesD"
                                onClick={() =>
                                  assignShiftToSB(
                                    sbobj.name,
                                    sbobj.id,
                                    sbobj.profile_pic,
                                    shifts,
                                    sbobj.weekday,
                                    sbobj.weekdate
                                  )
                                }
                              >
                                <Button className="SiftAssignToBt">
                                  Assign
                                </Button>
                              </div>
                            </div>

                            {shifts.assigned_employee_details.length > 0 ? (
                              <label class="assignedShi">
                                Currently Assigned to Shift
                              </label>
                            ) : (
                              <label class="assignedShi">
                                No Employees Assigned To Shift
                              </label>
                            )}
                            {shifts.assigned_employee_details.length > 0 &&
                              shifts.assigned_employee_details.map(
                                (empinfo, empindex) => (
                                  <div className="assignShiProLine">
                                    <div className="assignShiProLineInr">
                                      <div className="assignShiPro">
                                        <img src={empinfo.profile_pic} />
                                      </div>
                                      <h1>{empinfo.emp_name}</h1>
                                    </div>
                                    <div
                                      className="relesD"
                                      key={empindex}
                                      onClick={() =>
                                        releaseEmp(
                                          sbobj.name,
                                          empinfo.emp_id,
                                          sbobj.id,
                                          shifts.shifttime,
                                          sbobj.location,
                                          sbobj.weekday,
                                          sbobj.weekdate
                                        )
                                      }
                                    >
                                      Release{" "}
                                      <CloseIcon className="relesEmpIco" />
                                    </div>
                                  </div>
                                )
                              )}
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </DialogContentText>
            </DialogContent> */}
          </Dialog>
        </React.Fragment>
      </div>

      {/* Change Shift Moddal */}
      <div className="">
        <React.Fragment>
          <Dialog
            open={ChangeShift}
            onClose={handleCloseChangeShift}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="myAppModalMain forBeforAfterMod"
          >
            <DialogTitle
              id="alert-dialog-title"
              style={{ background: "#f6f6f6" }}
            >
              <h1
                className="modalHeading"
                style={{ marginTop: 0, paddingTop: 25 }}
              >
                <h6 className="extrHeading">Change or Assign New Shift</h6>
                Change or assign new shift to{" "}
                <b style={{ color: "#990000" }}>{aobj.name}</b> for
                <br />
                <b className="alrdLoc">
                  {aobj.location} on {aobj.weekday} <span>{aobj.weekdate}</span>
                </b>
              </h1>
              <IconButton
                className="modalCloseIco"
                onClick={handleCloseChangeShift}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent style={{ padding: 0 }}>
              <DialogContentText id="alert-dialog-description">
                <div className="modalBodyCont">
                  <div className="previewScheduModMain">
                    <div className="assignedShiftDivMain">
                      {ashiftsinfo.length > 0 &&
                        ashiftsinfo.map((assignshifts, ashiftsindex) => (
                          <div
                            className="changeShifLine"
                            style={{ marginBottom: 0 }}
                          >
                            <label className="assignedShi">
                              Assigned Shifts
                            </label>
                            <p>
                              <div className="forEditSft">
                                {assignshifts.shifttime}{" "}
                                <EditIcon
                                  onClick={() =>
                                    clickEdi(aobj, assignshifts.shifttime)
                                  }
                                />
                              </div>
                              {/* {assignshifts.shifttime}{" "} */}
                              <em
                                style={{ alignItems: "center" }}
                                onClick={() =>
                                  releaseEmpBeforeChangeShift(
                                    aobj.name,
                                    aobj.id,
                                    assignshifts.shifttime,
                                    aobj.location,
                                    aobj.weekday,
                                    aobj.weekdate,
                                    "self"
                                  )
                                }
                              >
                                Release <CloseIcon className="relesEmpIco" />
                              </em>
                            </p>

                            <div className="editAssignedShiTime" id="EditSftId">
                              <TimeRangePicker
                                format="hh:mm aa"
                                showMeridiem
                                value={[startTimeedit, endTimeedit]}
                                onChange={handleTimeChange}
                              />
                              <div className="timePicAction">
                                <IconButton aria-label="edit">
                                  <CheckIcon
                                    style={{ color: "green" }}
                                    onClick={() => updateEmployeeLocalShift()}
                                  />
                                </IconButton>
                                <IconButton
                                  aria-label="delete"
                                  onClick={closeEdit}
                                >
                                  <CloseIcon style={{ color: "red" }} />
                                </IconButton>
                              </div>
                            </div>
                          </div>
                        ))}
                      <div
                        className="schedularAccordianMain"
                        style={{ marginTop: 15 }}
                      >
                        <Accordion defaultExpanded>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                          >
                            <Typography>Change Shift</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Typography>
                              <Grid container spacing={2}>
                                <Grid item xs={12} md={12}>
                                  <label className="myLable">
                                    Select Location
                                  </label>
                                  <div className="openDropMain">
                                    <div className="openDropInr">
                                      <FormControl>
                                        <RadioGroup
                                          aria-labelledby="demo-radio-buttons-group-label"
                                          defaultValue={defaultloc}
                                          name="radio-buttons-group"
                                          onChange={(e) =>
                                            handleChangeShiftLocation(
                                              e.target.value,
                                              aobj.location,
                                              aobj.name,
                                              aobj.id,
                                              aobj.weekday,
                                              aobj.weekdate
                                            )
                                          }
                                        >
                                          {ashiftsloc.length > 0 &&
                                            ashiftsloc.map((loc, locindex) => (
                                              <FormControlLabel
                                                key={locindex}
                                                value={loc.shift_location}
                                                control={<Radio />}
                                                label={loc.shift_location}
                                              />
                                            ))}
                                        </RadioGroup>
                                      </FormControl>
                                    </div>
                                  </div>
                                </Grid>
                                <Grid item xs={12} md={12}>
                                  <label className="myLable">
                                    Select Shift
                                  </label>
                                  <div className="openDropMain">
                                    <div className="openDropInr">
                                      {shiftsfromloc.length > 0 ? (
                                        <FormControl>
                                          <RadioGroup
                                            aria-labelledby="demo-radio-buttons-group-label"
                                            //defaultValue="8:00AM-1:00PM"
                                            name="radio-buttons-group"
                                            onChange={(e) =>
                                              handleChangeShiftLocationTime(
                                                e,
                                                aobj.id,
                                                aobj.location,
                                                aobj.weekdate,
                                                aobj.weekday
                                              )
                                            }
                                            value={shiftloctimevalue}
                                          >
                                            {shiftsfromloc.length > 0 &&
                                              shiftsfromloc.map(
                                                (shift, index) => (
                                                  <div>
                                                    <FormControlLabel
                                                      control={<Radio />}
                                                      label={
                                                        shift.shift_time +
                                                        " " +
                                                        shift.status
                                                      }
                                                      key={index}
                                                      value={
                                                        shift.shift_time +
                                                        "=>" +
                                                        shift.status +
                                                        "=>" +
                                                        shift.frequencyid +
                                                        "=>" +
                                                        shift.sch_id
                                                      }
                                                      className={
                                                        shift.status == "SB"
                                                          ? "shiftStatusCss"
                                                          : "shiftStatusCssU"
                                                      }
                                                    />
                                                    {shift.hasOwnProperty(
                                                      "shiftadded"
                                                    ) &&
                                                      (shift.shiftadded ==
                                                        "Local" ||
                                                        shift.shiftadded ==
                                                          "employeeLocal") && (
                                                        <em className="localTxt">
                                                          Local
                                                        </em>
                                                      )}
                                                  </div>
                                                )
                                              )}
                                          </RadioGroup>
                                        </FormControl>
                                      ) : (
                                        <p>No Shift Found</p>
                                      )}
                                    </div>
                                  </div>
                                </Grid>
                                {/* <Grid item xs={12} md={12}>
                                  <select
                                    className="myInput"
                                    onChange={(e) =>
                                      handleChangeShiftLocation(
                                        e,
                                        aobj.location,
                                        aobj.name,
                                        aobj.id,
                                        aobj.weekday,
                                        aobj.weekdate
                                      )
                                    }
                                  >
                                    <option>Select Location</option>
                                    {ashiftsloc.length > 0 &&
                                      ashiftsloc.map((loc, locindex) => (
                                        <option
                                          key={locindex}
                                          value={loc.shift_location}
                                        >
                                          {loc.shift_location}
                                        </option>
                                      ))}
                                  </select>
                                </Grid>

                                <Grid item xs={12} md={12}>
                                  {shiftsfromloc.length > 0 ? (
                                    <select
                                      className="myInput"
                                      onChange={(e) =>
                                        handleChangeShiftLocationTime(
                                          e,
                                          aobj.id,
                                          aobj.location,
                                          aobj.weekdate,
                                          aobj.weekday
                                        )
                                      }
                                      value={shiftloctimevalue}
                                    >
                                      <option>Select Shift</option>
                                      {shiftsfromloc.length > 0 &&
                                        shiftsfromloc.map((shift, index) => (
                                          <option
                                            key={index}
                                            value={
                                              shift.shift_time +
                                              "=>" +
                                              shift.status +
                                              "=>" +
                                              shift.frequencyid +
                                              "=>" +
                                              shift.sch_id
                                            }
                                            className={
                                              shift.status == "SB"
                                                ? "shiftStatusCss"
                                                : "shiftStatusCssU"
                                            }
                                          >
                                            {shift.shift_time}{" "}
                                            <p>{shift.status}</p>
                                          </option>
                                        ))}
                                    </select>
                                  ) : (
                                    <p>No Shift Found</p>
                                  )}
                                </Grid> */}
                                {shiftloc != "" &&
                                  shiftloctime != "" &&
                                  othershiftinfo.length > 0 && (
                                    <Grid item xs={12} md={12}>
                                      <div className="alrdAssignedView">
                                        <b className="alrdAssignedHead">
                                          Currently Assigned
                                        </b>
                                        <h1>
                                          <RoomIcon /> {shiftloc}
                                        </h1>
                                        <h2>
                                          <AccessTimeFilledIcon />{" "}
                                          {shiftloctime}
                                        </h2>

                                        <div className="assignedEmpName">
                                          {othershiftinfo.length > 0 &&
                                            othershiftinfo.map(
                                              (empinfo, index) => (
                                                <p>
                                                  <b>
                                                    <span></span>
                                                    {empinfo.full_name}
                                                  </b>
                                                  <em
                                                    onClick={() =>
                                                      releaseEmpBeforeChangeShift(
                                                        empinfo.full_name,
                                                        empinfo.emp_id,
                                                        shiftloctime,
                                                        shiftloc,
                                                        aobj.weekday,
                                                        aobj.weekdate,
                                                        "other"
                                                      )
                                                    }
                                                  >
                                                    Release <CloseIcon />
                                                  </em>
                                                </p>
                                              )
                                            )}
                                        </div>
                                      </div>
                                    </Grid>
                                  )}
                                <Grid item xs={12} md={12}>
                                  <div className="modNewBtnOuter">
                                    <Button
                                      onClick={() =>
                                        changeAssingedShiftofEmp(
                                          aobj.name,
                                          aobj.id,
                                          aobj.profile_pic,
                                          aobj.weekday,
                                          aobj.weekdate
                                        )
                                      }
                                      disabled={shiftloctime == ""}
                                    >
                                      Save
                                    </Button>
                                  </div>
                                </Grid>
                              </Grid>
                            </Typography>
                          </AccordionDetails>
                        </Accordion>
                      </div>
                    </div>
                  </div>
                </div>
              </DialogContentText>
            </DialogContent>
          </Dialog>
        </React.Fragment>
      </div>

      {/* add and edit shift */}
      <div className="">
        <React.Fragment>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="myAppModalMain forBeforAfterMod"
          >
            <DialogTitle id="alert-dialog-title">
              <h1 className="modalHeading">
                {flag == "edit" ? "Edit" : "Add"} Shift
              </h1>
              <IconButton className="modalCloseIco" onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <div className="modalBodyCont">
                  <div className="schecdulEditMain">
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={12}>
                        <label className="myLable">Shift Hours </label>
                        <TimeRangePicker
                          format="hh:mm aa"
                          showMeridiem
                          value={[startTimeedit, endTimeedit]}
                          onChange={handleTimeChange}
                        />
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <label className="myLable">Employees per shift</label>
                        <div className="countD newConInModal">
                          <button
                            onClick={() => {
                              if (flag === "edit") {
                                if (editslot.required_emp_count > 0) {
                                  decrementCount2();
                                }
                              } else {
                                if (empCount > 0) {
                                  decrementCount();
                                }
                              }
                            }}
                          >
                            -
                          </button>
                          <div>
                            {flag == "edit"
                              ? editslot.required_emp_count
                              : empCount}
                          </div>
                          <button
                            onClick={() => {
                              if (flag === "edit") {
                                if (
                                  totalempcount > editslot.required_emp_count
                                ) {
                                  incrementCount2();
                                }
                              } else {
                                if (totalempcount > empCount) {
                                  incrementCount();
                                }
                              }
                            }}
                          >
                            +
                          </button>
                        </div>
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <label className="myLable">Enter Shift Name</label>
                        <div className=" ">
                          <select
                            className="myInput"
                            value={filterFreq}
                            onChange={(e) => handleChangeShiftLabel(e)}
                          >
                            <option value="">Select Shift Name</option>
                            {freqList.map((item) => (
                              <option
                                key={item.frequancyid}
                                value={item.frequancyid}
                              >
                                {item.frequancy_name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={12}
                        style={{ textAlign: "center" }}
                      >
                        <Button
                          className="modBt"
                          onClick={() => AddUpdateShift(flag, editslot)}
                        >
                          {flag == "edit" ? "Update" : "Save"}
                        </Button>
                      </Grid>
                    </Grid>
                  </div>
                </div>
              </DialogContentText>
            </DialogContent>
          </Dialog>
        </React.Fragment>
      </div>
    </>
  );
}

export default SchedulPreview;
