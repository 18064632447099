import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined";
import CloseIcon from "@mui/icons-material/Close";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import LockPersonIcon from "@mui/icons-material/LockPerson";
import VerifiedIcon from "@mui/icons-material/Verified";
import { Button, Grid } from "@mui/material";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Switch from "@mui/material/Switch";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import ToggleButton from "@mui/material/ToggleButton";
import moment from "moment/moment";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import "react-medium-image-zoom/dist/styles.css";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import swal from "sweetalert";
import avatarPic from "../../../assets/images/avtarPic.png";
import noPic from "../../../assets/images/noTasksAdded.svg";
import unlock from "../../../assets/images/unlock.png";
import {
  makeGetRequest,
  makePostRequest,
  uploadFileRequest,
} from "../../../utils/utils";

import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import CreateIcon from "@mui/icons-material/Create";
import GroupIcon from "@mui/icons-material/Group";
import PersonIcon from "@mui/icons-material/Person";
import Person2Icon from "@mui/icons-material/Person2";
import imageCompression from "browser-image-compression";
import Zoom from "react-medium-image-zoom";
import videoLoadImg from "../../../assets/images/Video_Loader.gif";
import TopBar from "../../admin/TopBar";
import UserBottom from "../UserBottom/UserBottom";

// For Tabs
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
// ENd

function getDatesArray(startDate, endDate) {
  const dates = [];
  const start = moment(startDate);
  const end = moment(endDate);

  while (start.isSameOrBefore(end)) {
    dates.push({
      date: start.format("YYYY-MM-DD"),
      day: start.format("ddd"),
      formatdate: start.format("DD"),
    });
    start.add(1, "day");
  }

  return dates;
}

function EmployeeTaskListNew() {
  const navigate = useNavigate();
  const [loc, setLoc] = useState([]);
  const [locationDefault, setLocationDefault] = React.useState("");
  const [schedulelist, setScheduleList] = React.useState([]);
  const [schedulefrequancylist, setScheduleFrequancyList] = React.useState([]);
  const [selectedDay, setSelectedDay] = useState("Monday");
  const [currentIndex, setCurrentIndex] = useState(0);
  const [totalOutOfTask, setTotalOutOfTask] = useState(0);
  let percentage = 0;
  const sliderRef = useRef(null);

  const handleNext = () => {
    const nextIndex = currentIndex + 1;
    if (loc.length > nextIndex) {
      sliderRef.current.slickNext();
      setCurrentIndex(nextIndex);
      // getSelectCount(categoryid, loc[nextIndex].location)
    }
  };

  const handlePrev = () => {
    const prevIndex = currentIndex - 1;
    if (prevIndex >= 0) {
      sliderRef.current.slickPrev();
      setCurrentIndex(prevIndex);
      // getSelectCount(categoryid, loc[prevIndex].location)
    }
  };

  function SelectSliders(idx) {
    setCurrentIndex(idx);
    //getSelectCount(categoryid, loc[idx].location)
  }

  const convertStartStringTime = () => {
    let consplit = localStorage.getItem("loginEmpShiftTime");
    if (consplit != "") {
      let splitstring = consplit.split("-");
      const startTime = splitstring[0].trim(); // Output: "4:00 PM"
      const formattedTime = moment(startTime, "h:mm A").format("HH:mm:ss");
      return formattedTime;
    } else {
      return "";
    }
  };

  const convertEndStringTime = () => {
    let consplit = localStorage.getItem("loginEmpShiftTime");

    if (consplit != "") {
      let splitstring = consplit.split("-");
      const endTime = splitstring[1].trim(); // Output: "6:00 PM"
      const formattedTime = moment(endTime, "h:mm A").format("HH:mm:ss");
      return formattedTime;
    } else {
      return "";
    }
  };

  const settings2 = {
    dots: true,
    infinite: false,
    arrows: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  //For Scroll Add Class
  const [scrolling, setScrolling] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        // Adjust this value based on your needs
        setScrolling(true);
      } else {
        setScrolling(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  // End

  const label = { inputProps: { "aria-label": "Switch demo" } };

  const [selected, setSelected] = React.useState(false);
  const [sortColumnName, setSortColumnName] = React.useState("task_name");
  const [sortBy, setSortBy] = React.useState("asc");
  const [upload, setUpload] = React.useState(false);
  const [categoryList, setCategoryList] = React.useState([]);
  const [showLoader, setShowLoader] = React.useState(false);
  const [showLoaderImage, setShowLoaderImage] = React.useState(false);
  const [categoryid, setCategoryID] = React.useState([]);
  const [categoryname, setCategoryName] = React.useState([]);
  const [frequancyid, setFrequancyID] = React.useState([]);
  const [freequancyList, setFreequancyList] = React.useState([]);
  const [slider1, setSlider1] = useState(null);
  const [mondaysCal, setMondaysCal] = React.useState([]);
  const [taskid, setTaskID] = React.useState(0);
  const [tempSlide, setTempSlide] = React.useState("");
  const [todaysDate, setTodaysDate] = useState({ time: new Date() });
  const [currentweekCal, setCurrentWeekCal] = React.useState([]);
  const [startDate, setStartDate] = React.useState(
    moment(todaysDate.time).format("YYYY-MM-DD")
  );
  const [startADate, setStartADate] = React.useState(
    moment(todaysDate.time).format("YYYY-MM-DD")
  );

  const [sendcurrentTime, setSendCurrentTime] = React.useState(
    moment(todaysDate.time).format("HH:mm")
  );

  const [endDate, setEndDate] = React.useState(
    moment().endOf("year").add(1, "year")
  );
  const [datesArray, setDatesArray] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [dropDownSelect, setDropDownSelect] = useState(null);
  const [UserId, setUserId] = useState();
  const [weekDay, setWeekDay] = useState(moment().format("dddd"));
  const [employeeTaskList, setEmployeeTaskList] = useState([]);
  const [completeCount, setCompleteCount] = useState(0);
  const [completePercentage, setCompletePercentage] = useState(0);
  const [completeIteration, setCompleteIteration] = useState(0);
  const [growthPercentage, setGrowthPercentage] = useState(10);
  const [avaialableEmployee, setAvaialableEmployee] = useState([]);
  const [availablecnt, setAvailableCnt] = useState(0);
  const [localImage, setLocalImage] = useState("");
  const [selectedValue, setSelectedValue] = useState([]);
  const [UserName, setUserName] = useState();
  const [companypic, setCompanyPic] = useState();
  const [employeeDetails, setEmployeeDetails] = useState([]);
  const [clickCount, setClickCount] = useState(0);
  const [loginErrMsg, setLoginErrMsg] = React.useState("");
  const initialValues = [100];
  const [imageUploadVariables, setImageUploadVariables] = useState({
    taskid: "",
    userid: "",
  });

  const [imageBeforeErrMsg, setImageBeforeErrMsg] = React.useState("");
  const [imageAfterErrMsg, setImageAfterErrMsg] = React.useState("");

  const [shiftStartTime, setShiftStartTime] = useState(
    convertStartStringTime()
  );
  const [shiftEndTime, setShiftEndTime] = useState(convertEndStringTime());
  const [currentTime, setCurrentTime] = useState(moment().format("HH:mm:ss"));

  async function compressImageBefore(file) {
    const options = {
      maxSizeMB: 1, // Set the max size to 1MB
      maxWidthOrHeight: 1024, // Set the maximum width or height
      useWebWorker: true, // Use web worker for better performance
    };
    try {
      const imageCompress = await imageCompression(file, options);
      const uniqueFileName = `${Date.now()}_${imageCompress.name}`;
      const renamedFile = new File([imageCompress], uniqueFileName, {
        type: imageCompress.type,
      });
      const uploadDone = await uploadImageOrFile(renamedFile);
      setImageUploadBefore([...ImageUploadBefore, uploadDone]);
      setShowLoader(false);
      return true;
    } catch (error) {
      console.log(error);
    }
  }

  async function uploadImagesBefore(e) {
    if (e.target.files[0]) {
      setImageBeforeErrMsg("");
      setShowLoaderImage(true);
      const file = e.target.files[0];
      //console.log(e.target.file[0].size);
      let validateRes = false;
      if (await validateImageVideo(file)) {
        if (file.name.match(/.(jpg|JPG|jpeg|JPEG|png|PNG|gif|GIF)$/i)) {
          if (await compressImageBefore(file)) {
            setShowLoaderImage(false);
          } else {
            setShowLoaderImage(false);
          }
        } else if (file.name.match(/.(mp4|MP4|3gp|3GP|flv|FLV)$/i)) {
          const vidioUploadDone = await uploadImageOrFile(file);
          console.log("Video", vidioUploadDone);
          setImageUploadBefore([...ImageUploadBefore, vidioUploadDone]);
        } else {
          setImageBeforeErrMsg("Invalid file format");
        }
        // var logoUrl = await uploadImageOrFile(file);
        // setImageUploadBefore([...ImageUploadBefore, logoUrl]);
      } else {
        setImageBeforeErrMsg("Invalid file format");
      }
      setShowLoaderImage(false);
    } else {
      setShowLoaderImage(false);
    }
  }

  const deleteImgBefore = (index) => {
    setShowLoaderImage(true);
    ImageUploadBefore.splice(index, 1);
    setImageUploadBefore([...ImageUploadBefore]);
    setShowLoaderImage(false);
  };

  async function compressImageAfter(file) {
    const options = {
      maxSizeMB: 1, // Set the max size to 1MB
      maxWidthOrHeight: 1024, // Set the maximum width or height
      useWebWorker: true, // Use web worker for better performance
    };
    try {
      const imageCompress = await imageCompression(file, options);
      const uniqueFileName = `${Date.now()}_${imageCompress.name}`;
      const renamedFile = new File([imageCompress], uniqueFileName, {
        type: imageCompress.type,
      });
      const uploadDone = await uploadImageOrFile(renamedFile);
      setImageUploadAfter([...ImageUploadAfter, uploadDone]);
      setShowLoaderImage(false);
      return true;
    } catch (error) {
      console.log(error);
    }
  }

  async function uploadImagesAfter(e) {
    if (e.target.files[0]) {
      setImageAfterErrMsg("");
      setShowLoaderImage(true);
      const file = e.target.files[0];
      //console.log(e.target.file[0].size);
      let validateRes = false;
      if (await validateImageVideo(file)) {
        console.log(file);
        if (file.name.match(/.(jpg|JPG|jpeg|JPEG|png|PNG|gif|GIF)$/i)) {
          if (await compressImageAfter(file)) {
            setShowLoaderImage(false);
          } else {
            setShowLoaderImage(false);
          }
        } else if (file.name.match(/.(mp4|MP4|3gp|3GP|flv|FLV)$/i)) {
          const vidioUploadDone = await uploadImageOrFile(file);
          console.log("Video", vidioUploadDone);
          setImageUploadAfter([...ImageUploadAfter, vidioUploadDone]);
        } else {
          setImageAfterErrMsg("Invalid file format");
        }
        //var logoUrl = await uploadImageOrFile(file);
        //setImageUploadAfter([...ImageUploadAfter, logoUrl]);
      } else {
        setImageAfterErrMsg("Invalid file format");
      }
      setShowLoaderImage(false);
    } else {
      setShowLoaderImage(false);
    }
  }

  const deleteImgAfter = (index) => {
    setShowLoaderImage(true);
    ImageUploadAfter.splice(index, 1);
    setImageUploadAfter([...ImageUploadAfter]);
    setShowLoaderImage(false);
  };

  async function uploadImageOrFile(fileData) {
    setShowLoaderImage(true);
    console.log(fileData);
    const formData = new FormData();
    var fileUrl;
    if (fileData.name.match(/.(jpg|JPG|jpeg|JPEG|png|PNG|gif|GIF)$/i)) {
      formData.append("image", fileData);
      await uploadFileRequest("superadmin/upload", formData)
        .then((response) => {
          fileUrl = response.data.imagepath;
        })
        .catch((err) => {
          setShowLoaderImage(false);
          let errMsg = err.response.data.message;
          console.log(err.response.data.message);
        });
    } else if (fileData.name.match(/.(mp4|MP4|3gp|3GP|flv|FLV)$/i)) {
      formData.append("file", fileData);
      await uploadFileRequest("superadmin/uploadvideo", formData)
        .then((response) => {
          fileUrl = response.data.imagepath;
        })
        .catch((err) => {
          setShowLoaderImage(false);
          let errMsg = err.response.data.message;
          console.log(err.response.data.message);
        });
    }
    setShowLoaderImage(false);
    console.log("upload", fileUrl);
    return fileUrl;
  }

  async function validateImage(fileData) {
    const fileSize = fileData.size; // Size in bytes
    const fileName = fileData.name;
    console.log("File Name", fileName, "File Size", fileSize);
    console.log("fileData", fileData.size);
    if (fileData.name.match(/.(jpg|JPG|jpeg|JPEG|png|PNG|gif|GIF)$/i)) {
      return true;
    } else return false;
  }

  async function validateImageVideo(fileData) {
    const fileSize = fileData.size; // Size in bytes
    const fileName = fileData.name;
    console.log("File Name", fileName, "File Size", fileSize);
    console.log("fileData", fileData.size);
    if (
      fileData.name.match(
        /.(jpg|JPG|jpeg|JPEG|png|PNG|gif|GIF|mp4|MP4|3gp|3GP|flv|FLV)$/i
      )
    ) {
      return true;
    } else return false;
  }

  const [employeeImageErrMsg, setEmployeeImageErrMsg] = React.useState("");

  async function compressProfileImage(file) {
    const options = {
      maxSizeMB: 1, // Set the max size to 1MB
      maxWidthOrHeight: 1024, // Set the maximum width or height
      useWebWorker: true, // Use web worker for better performance
    };
    try {
      const imageCompress = await imageCompression(file, options);
      const uniqueFileName = `${Date.now()}_${imageCompress.name}`;
      const renamedFile = new File([imageCompress], uniqueFileName, {
        type: imageCompress.type,
      });
      const uploadDone = await uploadImageOrFile(renamedFile);
      let id = localStorage.getItem("userId");
      await uploadProfilepic(uploadDone, id);
      setShowLoaderImage(false);
      return true;
    } catch (error) {
      console.log(error);
    }
  }

  async function uploadImage(e) {
    setEmployeeImageErrMsg("");
    setShowLoaderImage(true);
    const file = e.target.files[0];
    if (await validateImage(file)) {
      if (await compressProfileImage(file)) {
        setShowLoaderImage(false);
      } else {
        setShowLoaderImage(false);
      }
      /* console.log("After Validate", file);
        const imageUpload = await uploadImageOrFile(file);
        console.log("image Upload", imageUpload)
        let id = localStorage.getItem("userId");
        await uploadProfilepic(imageUpload, id); */
    } else {
      setEmployeeImageErrMsg("Invalid file format");
    }
    //updateImage();
    setShowLoaderImage(false);
  }

  const uploadProfilepic = (Img, id) => {
    console.log(Img);
    console.log(id);

    const formData = {
      employee_id: parseInt(id),
      profile_pic: Img,
      contact_no: employeeDetails.contact_no,
      email_id: employeeDetails.email_id,
      company_id: employeeDetails.company_id,
      employee_code: employeeDetails.employee_code,
      schemaname: employeeDetails.schemaname,
    };
    makePostRequest("employee/updateemployee", formData)
      .then((response) => {
        if (response.data) {
          swal("Success", "Image updated successfully", "success", {
            timer: 3000,
            buttons: false,
          });
        }
        let id = localStorage.getItem("userId");
        getEmployeeDetails(id);
      })
      .catch((err) => {
        let errMsg = err.response.data.message;
        console.log(err.response.data.message);
      });
  };

  const updateTaskSchedular = () => {
    console.log("ImgVariable", imageUploadVariables);
    setImageUploadAfter([]);
    setImageUploadBefore([]);
    setShowLoader(false);
    /* values.taskid = parseInt(taskid);
    values.empid = parseInt(empid);
    let catarray = parseInt(dropDownSelect);
    values.categoryid = catarray;
    values.taskassigndatetime = moment().format('YYYY-MM-DD'); */
    let formData = {
      taskid: imageUploadVariables.taskid,
      empid: parseInt(imageUploadVariables.userid),
      categoryid: parseInt(dropDownSelect),
      before_img: ImageUploadBefore,
      after_img: ImageUploadAfter,
      taskassigndatetime: moment().format("YYYY-MM-DD"),
    };
    makePostRequest("employeeschedular/updateassigntask", formData)
      .then((response) => {
        if (response.data) {
          getSerachList();
          setLoginErrMsg("");
          setShowLoader(false);
          swal("Success", "Information updated successfully", "success", {
            timer: 3000,
            buttons: false,
          });
          handleClose();
          setImageUploadAfter([]);
          setImageUploadBefore([]);
        }
      })
      .catch((err) => {
        let errMsg = err.message;
        setShowLoader(false);
        setLoginErrMsg(errMsg);
      });
  };

  function handleChangeSortBy() {
    setSortColumnName("task_name");
    setSelected(!selected);
    if (sortBy == "desc") setSortBy("asc");
    else setSortBy("desc");
    setUpload(!upload);
    getSerachList(searchText);
  }

  // For Modal
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = (taskid, userId, taskObj) => {
    console.log("TaskId", taskid, "Userid", userId);
    console.log(taskObj);
    if (taskObj.assigntask != undefined) {
      if (taskObj.assigntask[0].empid == userId) {
        if (taskObj.assigntask[0].before_img != null)
          setImageUploadBefore([...taskObj.assigntask[0].before_img]);
        else setImageUploadBefore([]);
        if (taskObj.assigntask[0].after_img != null)
          setImageUploadAfter([...taskObj.assigntask[0].after_img]);
        else setImageUploadAfter([]);
        setImageUploadVariables({
          taskid: taskid,
          userid: userId,
        });
        getCompletedTaskCount(parseInt(dropDownSelect), startADate);
        setOpen(true);
      } else {
        swal(
          "This Task is Already Assigned to Someone else ",
          "Warning",
          "warning",
          {
            timer: 3000,
            buttons: false,
          }
        );
        setOpen(false);
      }
    } else {
      swal("Please assign Task First", "Warning", "warning", {
        timer: 3000,
        buttons: false,
      });
      setOpen(false);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };
  // End

  function getArrowDatesArray(startDate, endDate) {
    const dates = [];
    const start = moment(startDate);
    const end = moment(endDate);

    while (start.isSameOrBefore(end)) {
      dates.push(start.format("YYYY-MM-DD"));
      start.add(1, "day");
    }

    //console.log("new dates===>"+JSON.stringify(dates))

    return dates;
  }

  const datesArrayVal = getDatesArray(startDate, endDate);
  const today = moment();
  const todayIndex = datesArrayVal.findIndex((arraydate) =>
    moment(arraydate.date).isSame(today, "day")
  );

  const settings = {
    initialSlide: todayIndex,
    // className: "center",
    // centerMode: false,
    // infinite: true,
    // centerPadding: "0px",
    // slidesToShow: 3,
    // speed: 500,
    // nextArrow: <NextArrow />,
    // prevArrow: <PrevArrow />,

    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: false,
    arrows: true,
    dots: false,
    speed: 300,
    centerPadding: "20px",
    infinite: true,
    autoplaySpeed: 5000,
    //autoplay: true,
    // nextArrow: <NextArrow />,
  };

  const forSelectList = () => {
    // var element = document.getElementById("listID");
    // element.classList.toggle("selectedList");
  };

  function getMonday(d) {
    var date = new Date(d);
    const day = date.getDay();
    const diff = date.getDate() - day + (day === 0 ? -6 : 1);
    return new Date(d.setDate(diff));
  }

  // For Tabs
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  // End

  function endOfWeek(date) {
    var lastday = date.getDate() - (date.getDay() - 1) + 7;
    return new Date(date.setDate(lastday)).toISOString().slice(0, 10);
  }

  function getSelectedWeek(startDate) {
    let week = [];
    var newdates = new Date(startDate);
    var d = new Date(startDate);
    d.setDate(1);
    var curr = getMonday(newdates);
    var xday = curr.getDate();
    d.setDate(xday);

    for (let i = 1; i <= 7; i++) {
      let first = curr.getDate() - curr.getDay() + i;
      let day = new Date(curr.setDate(first)).toISOString().slice(0, 10);
      week.push({ CurrentWeek: day });
    }
    setCurrentWeekCal(week);
  }

  function SelectSliders(idx, obs) {
    setStartADate(obs);
    const date = moment(obs);
    const dayName = date.format("dddd");
    setWeekDay(dayName);
    slider1?.slickGoTo(idx);
    ChangeDateFun(categoryid, obs, dayName);
  }

  const changeList = (e, id, catname) => {
    //setShowLoader(true);
    setCategoryID(id);
    setCategoryName(catname);
    const obsdate = moment(todaysDate.time).format("YYYY-MM-DD");
    setStartADate(moment(todaysDate.time).format("YYYY-MM-DD"));
    const date = moment(moment(todaysDate.time).format("YYYY-MM-DD"));
    const dayName = date.format("dddd");
    setWeekDay(dayName);
    ChangeDateFun(id, obsdate, dayName);
  };

  const handleClick = async (
    index,
    taskid,
    empid,
    taskstatus,
    location,
    multiPerson
  ) => {
    setClickCount((prevCount) => (prevCount + 1) % 4); // Cycles through 0 to 3
    setShowLoader(true);
    let values = {};
    if (multiPerson) {
      if (taskstatus == "Open") {
        if (taskstatus == "Open") values.taskstatus = "Assigned";
        else if (taskstatus == "Assigned") values.taskstatus = "Completed";
        else if (taskstatus == "Completed") values.taskstatus = "Open";
        else values.taskstatus = "Open";
      } else {
        const taskStatusres = taskstatus.find((item) => item.empid == empid);
        if (taskStatusres) {
          if (taskStatusres.taskstatus == "Open")
            values.taskstatus = "Assigned";
          else if (taskStatusres.taskstatus == "Assigned")
            values.taskstatus = "Completed";
          else if (taskStatusres.taskstatus == "Completed")
            values.taskstatus = "Open";
          else values.taskstatus = "Open";
        } else {
          console.log("No record found");
        }
      }
    } else {
      if (taskstatus == "Open") {
        if (taskstatus == "Open") values.taskstatus = "Assigned";
        else if (taskstatus == "Assigned") values.taskstatus = "Completed";
        else if (taskstatus == "Completed") values.taskstatus = "Open";
        else values.taskstatus = "Open";
      } else {
        const taskStatusres = taskstatus.find((item) => item.empid == empid);

        if (taskStatusres) {
          if (taskStatusres.taskstatus == "Open")
            values.taskstatus = "Assigned";
          else if (taskStatusres.taskstatus == "Assigned")
            values.taskstatus = "Completed";
          else if (taskStatusres.taskstatus == "Completed")
            values.taskstatus = "Open";
          else values.taskstatus = "Open";
        } else {
          console.log("No record found");
        }
      }
    }

    values.taskid = parseInt(taskid);
    values.empid = parseInt(empid);
    let catarray = parseInt(dropDownSelect);
    values.categoryid = catarray;
    values.location = location;
    values.shifttime = localStorage.getItem("loginEmpShiftTime");
    values.taskassigndatetime = moment().format("YYYY-MM-DD");

    await makeGetRequest(
      "employeeschedular/selectassigntask/" +
        parseInt(empid) +
        "/" +
        parseInt(taskid) +
        "/" +
        parseInt(dropDownSelect) +
        "/'" +
        startDate +
        "'"
    )
      .then((response) => {
        console.log(
          "new response===>" + JSON.stringify(response.data.data.rows)
        );
        if (response.data.data.rowCount > 0) {
          let result = response.data.data.rows;
          if (result[0].assignid > 0 && result[0].taskstatus != "Completed") {
            makePostRequest("employeeschedular/updateassigntask", values)
              .then((response) => {
                if (response.data) {
                  // if(taskstatus == "Assigned")
                  // {
                  //   getStatusSerachList();
                  // }else{
                  getCompletedTaskCount(parseInt(dropDownSelect), startADate);
                  getSerachList();
                  //}

                  setLoginErrMsg("");
                  setShowLoader(false);
                }
              })
              .catch((err) => {
                let errMsg = err.message;
                setShowLoader(false);
                setLoginErrMsg(errMsg);
              });
          } else {
            makeGetRequest(
              "employeeschedular/deleteassigntask/" +
                parseInt(empid) +
                "/" +
                parseInt(taskid) +
                "/" +
                parseInt(dropDownSelect) +
                "/'" +
                startDate +
                "'/'" +
                localStorage.getItem("loginEmpShiftTime") +
                "'"
            )
              .then((response) => {
                getSerachList();
                setLoginErrMsg("");
                getCompletedTaskCount(parseInt(dropDownSelect), startADate);
                //setShowLoader(false);
              })
              .catch((err) => {
                getCompletedTaskCount(parseInt(dropDownSelect), startADate);
                getSerachList();
                setLoginErrMsg("");
                setShowLoader(false);
              });
          }
        } else {
          if (values.taskstatus != "Open") {
            makePostRequest("employeeschedular/insertassigntask", values)
              .then((response) => {
                if (response.data) {
                  getSerachList();
                  setLoginErrMsg("");
                  getCompletedTaskCount(parseInt(dropDownSelect), startADate);
                  //setShowLoader(false);
                }
              })
              .catch((err) => {
                let errMsg = err.message;
                setShowLoader(false);
                setLoginErrMsg(errMsg);
              });
          } else {
            getCompletedTaskCount(parseInt(dropDownSelect), startADate);
            getSerachList();
            setShowLoader(false);
          }
        }
      })
      .catch((err) => {});
  };

  const handleskip = async (event, fullname) => {
    swal(
      "Already Assigned",
      `This task is already assigned to ${fullname}`,
      "info",
      {
        timer: 3000,
        buttons: false,
      }
    );
  };

  const getEmployeeDetails = async (id) => {
    setShowLoader(true);
    await makeGetRequest("/employee/getemployee/" + parseInt(id))
      .then((response) => {
        if (response.data.data) {
          if (response.data.data.length > 0) {
            console.log(response.data.data[0]);
            setEmployeeDetails(response.data.data[0]);
          } else setEmployeeDetails([]);
          setUpload(!upload);
        }
        setShowLoader(false);
      })
      .catch((err) => {
        setShowLoader(false);
      });
  };

  const getEmployeeSchedulewithexisting = (id, catids) => {
    setShowLoader(true);
    // makeGetRequest("employeeschedular/employeesschedular/" + id + "/'" + startDate + "'")
    makeGetRequest(
      "employeeschedular/employeesassignednewschedular/" +
        id +
        "/'" +
        startDate +
        "'/'" +
        weekDay +
        "'/'" +
        sendcurrentTime +
        "'"
    )
      .then((response) => {
        console.log(
          "Check Bhai Check===>" + JSON.stringify(response.data.data)
        );
        if (response.data.data) {
          if (response.data.data.rows[0].categories.length > 0) {
            setSelectedValue(response.data.data.rows[0].categories);
            setCategoryList(response.data.data.rows[0].categories);
            setWeekDay(response.data.data.rows[0].weekday);

            setShowLoader(true);
            //"employeeschedular/employeeassigntask/" +
            makeGetRequest(
              "employeeschedular/employeeloginassigntask/" +
                id +
                "/'" +
                startDate +
                "'/" +
                catids +
                "/'" +
                response.data.data.rows[0].weekday +
                "'/'" +
                localStorage.getItem("loginEmpShiftLocation") +
                "'/" +
                localStorage.getItem("loginEmpShiftFrequancy") +
                "/" +
                sortColumnName +
                "/" +
                sortBy +
                "/" +
                null +
                "/'" +
                localStorage.getItem("loginEmpShiftTime") +
                "'"
            )
              .then((response) => {
                //console.log(response.data.data.rows);
                if (response.data.data.res.rows) {
                  //console.log(response.data.data);
                  //alert(response.data.data.rows[0].categories.length);
                  if (response.data.data.res.rows.length > 0) {
                    setEmployeeTaskList(response.data.data.res.rows);
                    setCompleteCount(
                      response.data.data.rescounts.rows[0].total_count
                    );

                    let totalTask =
                      response.data.data.res.rows[0].total_count_without_search;
                    setTotalOutOfTask(totalTask);
                    let completedTask =
                      response.data.data.rescounts.rows[0].total_count;

                    let Total = (completedTask * 100) / totalTask;
                    let FinalTotal = Math.round(Total);

                    setCompletePercentage(FinalTotal);
                    const iterations = FinalTotal / 10;
                    setCompleteIteration(iterations);
                    getCompletedTaskCount(catids, startDate);
                    setShowLoader(false);
                  } else {
                    setEmployeeTaskList([]);
                    setCompletePercentage(0);
                    setCompleteCount(0);
                    const iterations = 0;
                    setTotalOutOfTask(0);
                    setTotalOutOfTask(0);
                    getCompletedTaskCount(catids, startADate);
                    setCompleteIteration(iterations);
                    setShowLoader(false);
                  }
                  setUpload(!upload);
                }
              })
              .catch((err) => {
                setShowLoader(false);
              });
          } else {
            //setSelectedTaskList()
            //setIncValue(0)
            getCompletedTaskCount(catids, startDate);
            setShowLoader(false);
          }
          setUpload(!upload);
        }
      })
      .catch((err) => {
        setShowLoader(false);
      });
  };

  const getStatusSerachList = async (searchString) => {
    if (!searchString) {
      searchString = null;
      setSearchText(null);
    } else {
      setSearchText(searchString);
    }
    if (dropDownSelect > 0) {
      //setShowLoader(true);
      let userids = localStorage.getItem("userId");
      await makeGetRequest(
        "employeeschedular/employeereportstatustask/" +
          userids +
          "/'" +
          startADate +
          "'/" +
          dropDownSelect +
          "/'" +
          weekDay +
          "'" +
          "/" +
          sortColumnName +
          "/" +
          sortBy +
          "/" +
          searchString
      )
        .then((response) => {
          if (response.data.data.res.rows) {
            if (response.data.data.res.rows.length > 0) {
              setEmployeeTaskList(response.data.data.res.rows);
              //console.log("Sagar",response.data.data.res.rows)
              setCompleteCount(
                response.data.data.rescounts.rows[0].total_count
              );
              let totalTask = response.data.data.res.rowCount;
              let completedTask =
                response.data.data.rescounts.rows[0].total_count;

              let Total = (completedTask * 100) / totalTask;
              let FinalTotal = Math.round(Total);
              setCompletePercentage(FinalTotal);
              const iterations = FinalTotal / 10;
              setCompleteIteration(iterations);

              setCompleteCount(
                response.data.data.rescounts.rows[0].total_count
              );
              //setShowLoader(false);
            } else {
              setEmployeeTaskList([]);
              setCompletePercentage(0);
              setCompleteCount(0);
              const iterations = 0;
              setCompleteIteration(iterations);
              //setShowLoader(false);
            }
            setUpload(!upload);
          }
        })
        .catch((err) => {
          //setShowLoader(false);
        });
    } else {
      setEmployeeTaskList([]);
    }
  };

  async function activelocationList(day) {
    let bodyFormData = {
      weekday: day,
      template_name: localStorage.getItem("active_template"),
    };
    await makeGetRequest(
      "schedular/GetActiveTemplateLocations/" +
        localStorage.getItem("active_template")
    )
      .then((response) => {
        if (response.data.data) {
          let specificRecord;
          let newspecificRecord = [];
          if (localStorage.getItem("loginEmpShiftLocation")) {
            specificRecord = response.data.data.rows.find(
              (item) =>
                item.location === localStorage.getItem("loginEmpShiftLocation")
            );
            newspecificRecord.push(specificRecord);
            setLoc(newspecificRecord);
            setLocationDefault(newspecificRecord[0].location);
          } else {
            setLoc(response.data.data.rows);
            setLocationDefault(response.data.data.rows[0].location);
          }

          // if (categoryid == null || categoryid == "" || categoryid.length == 0) {
          //   getAllCategoryList(null, response.data.data.rows[0].location);
          // }
        } else {
          setLoc([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async function getAllScheduleList() {
    setShowLoader(true);
    await makeGetRequest(
      "schedular/getTemplateListForSummaryCount/" +
        parseInt(localStorage.getItem("companyId")) +
        "/false"
    )
      .then(async (response) => {
        if (response.data.data) {
          console.log("schedule", response.data.data);
          if (response.data.data.length > 0) {
            setScheduleList(response.data.data);

            setScheduleFrequancyList(response.data.data);
            let specificRecord;
            specificRecord = response.data.data.find(
              (item) => item.is_active === true
            );
            localStorage.setItem(
              "active_template",
              specificRecord.template_name
            );
            activelocationList(selectedDay);
          } else setScheduleList([]);
        }
      })
      .catch((err) => {
        setShowLoader(false);
      });
  }

  const getSerachList = async (searchString) => {
    if (!searchString) {
      searchString = null;
      setSearchText(null);
    } else {
      setSearchText(searchString);
    }
    if (dropDownSelect > 0) {
      //setShowLoader(true);
      let userids = localStorage.getItem("userId");
      //"employeeschedular/employeereporttask/" +
      await makeGetRequest(
        "employeeschedular/employeeloginreporttaskemployee/" +
          userids +
          "/'" +
          startADate +
          "'/" +
          dropDownSelect +
          "/'" +
          weekDay +
          "'" +
          "/'" +
          localStorage.getItem("loginEmpShiftLocation") +
          "'/" +
          localStorage.getItem("loginEmpShiftFrequancy") +
          "/'" +
          localStorage.getItem("loginEmpShiftTime") +
          "'/" +
          sortColumnName +
          "/" +
          sortBy +
          "/" +
          searchString
      )
        .then((response) => {
          if (response.data.data.res.rows) {
            if (response.data.data.res.rows.length > 0) {
              setEmployeeTaskList(response.data.data.res.rows);
              //console.log("Sagar",response.data.data.res.rows)
              setCompleteCount(
                response.data.data.rescounts.rows[0].total_count
              );
              // let totalTask = response.data.data.res.rowCount;

              let totalTask =
                response.data.data.res.rows[0].total_count_without_search;
              setTotalOutOfTask(totalTask);
              let completedTask =
                response.data.data.rescounts.rows[0].total_count;

              let Total = (completedTask * 100) / totalTask;
              let FinalTotal = Math.round(Total);
              setCompletePercentage(FinalTotal);
              const iterations = FinalTotal / 10;
              setCompleteIteration(iterations);
              setCompleteCount(
                response.data.data.rescounts.rows[0].total_count
              );
              //setShowLoader(false);
            } else {
              setEmployeeTaskList([]);
              setCompletePercentage(0);
              const iterations = 0;
              setTotalOutOfTask(0);
              setCompleteCount(0);
              setCompleteIteration(iterations);
              //setShowLoader(false);
            }
            setUpload(!upload);
          }
        })
        .catch((err) => {
          //setShowLoader(false);
        });
    } else {
      setEmployeeTaskList([]);
    }
  };

  const [ImageUploadBefore, setImageUploadBefore] = React.useState([]);
  const [ImageUploadAfter, setImageUploadAfter] = React.useState([]);

  async function getCompletedTaskCount(catid, startdate) {
    //selectindividualreporttask/
    await makeGetRequest(
      "employeeschedular/selectreporttask/" +
        catid +
        "/" +
        startdate +
        "/'" +
        localStorage.getItem("loginEmpShiftLocation") +
        "'/'" +
        localStorage.getItem("loginEmpShiftTime") +
        "'"
    )
      .then((response) => {
        console.log("My available employee list==>" + JSON.stringify(response));
        if (response.data.data.rows) {
          if (response.data.data.rows.length > 0) {
            setAvaialableEmployee(response.data.data.rows);
            setAvailableCnt(response.data.data.rowCount);
            setShowLoader(false);
            // let userIDsession = localStorage.getItem("userId");
            // getEmployeeSchedulewithexisting(parseInt(userIDsession),parseInt(catid));
          } else {
            setAvaialableEmployee([]);
            setAvailableCnt(0);
            // let userIDsession = localStorage.getItem("userId");
            // getEmployeeSchedulewithexisting(parseInt(userIDsession),parseInt(catid));
            setShowLoader(false);
          }

          setUpload(!upload);
        }
      })
      .catch((err) => {
        //setShowLoader(false);
      });
  }

  const statusChangeFun = async (catid) => {
    //if (!searchString) searchString = null;
    localStorage.setItem("categoryrsetid", catid);
    setDropDownSelect(catid);
    console.log("Selected Value", catid);

    //alert(UserId)
    if (catid > 0) {
      //setShowLoader(true);
      let userids = localStorage.getItem("userId");
      //"employeeschedular/employeereporttask/" +
      await makeGetRequest(
        "employeeschedular/employeeloginreporttaskemployee/" +
          userids +
          "/'" +
          startADate +
          "'/" +
          catid +
          "/'" +
          weekDay +
          "'" +
          "/'" +
          localStorage.getItem("loginEmpShiftLocation") +
          "'/" +
          localStorage.getItem("loginEmpShiftFrequancy") +
          "/'" +
          localStorage.getItem("loginEmpShiftTime") +
          "'/" +
          sortColumnName +
          "/" +
          sortBy +
          "/" +
          null
      )
        .then((response) => {
          //console.log(response.data.data.rows);
          if (response.data.data.res.rows) {
            //alert(response.data.data.rows[0].categories.length);
            if (response.data.data.res.rows.length > 0) {
              //console.log("Sagar",response.data.data.res.rows[0].assigntask[0].after_img)
              setEmployeeTaskList(response.data.data.res.rows);
              setCompleteCount(
                response.data.data.rescounts.rows[0].total_count
              );
              // let totalTask = response.data.data.res.rowCount;

              let totalTask =
                response.data.data.res.rows[0].total_count_without_search;
              setTotalOutOfTask(totalTask);
              let completedTask =
                response.data.data.rescounts.rows[0].total_count;

              let Total = (completedTask * 100) / totalTask;
              let FinalTotal = Math.round(Total);
              setCompletePercentage(FinalTotal);
              const iterations = FinalTotal / 10;
              setCompleteIteration(iterations);
              getCompletedTaskCount(catid, startADate);
            } else {
              setEmployeeTaskList([]);
              setAvaialableEmployee([]);
              setAvailableCnt(0);
              setTotalOutOfTask(0);
              setCompletePercentage(0);
              setCompleteCount(0);
              const iterations = 0;
              setCompleteIteration(iterations);
              //setShowLoader(false);
            }
            setUpload(!upload);
          }
        })
        .catch((err) => {
          //setShowLoader(false);
        });
    } else {
      setEmployeeTaskList([]);
      //setShowLoader(false);
    }
  };

  const ChangeDateFun = async (catid, searchdate, searchday) => {
    //if (!searchString) searchString = null;
    localStorage.setItem("categoryrsetid", catid);
    setDropDownSelect(catid);
    console.log("Selected Value", catid);

    //alert(UserId)
    if (catid > 0) {
      //setShowLoader(true);
      let userids = localStorage.getItem("userId");
      //"employeeschedular/employeereporttask/" +
      await makeGetRequest(
        "employeeschedular/employeeloginreporttaskemployee/" +
          userids +
          "/'" +
          searchdate +
          "'/" +
          catid +
          "/'" +
          searchday +
          "'" +
          "/'" +
          localStorage.getItem("loginEmpShiftLocation") +
          "'/" +
          localStorage.getItem("loginEmpShiftFrequancy") +
          "/'" +
          localStorage.getItem("loginEmpShiftTime") +
          "'/" +
          sortColumnName +
          "/" +
          sortBy +
          "/" +
          null
      )
        .then((response) => {
          //console.log(response.data.data.rows);
          if (response.data.data.res.rows) {
            //console.log(response.data.data);
            //alert(response.data.data.rows[0].categories.length);
            if (response.data.data.res.rows.length > 0) {
              //console.log("Sagar", response.data.data.res.rows[0].assigntask[0].after_img)
              //console.log("Sagar",response.data.data.res.rows[0].assigntask[0].before_img)

              setEmployeeTaskList(response.data.data.res.rows);
              setCompleteCount(
                response.data.data.rescounts.rows[0].total_count
              );
              // let totalTask = response.data.data.res.rowCount;

              let totalTask =
                response.data.data.res.rows[0].total_count_without_search;
              setTotalOutOfTask(totalTask);
              let completedTask =
                response.data.data.rescounts.rows[0].total_count;

              let Total = (completedTask * 100) / totalTask;
              let FinalTotal = Math.round(Total);
              setCompletePercentage(FinalTotal);
              const iterations = FinalTotal / 10;
              setCompleteIteration(iterations);
              getCompletedTaskCount(catid, searchdate);
              //setShowLoader(false);
            } else {
              setEmployeeTaskList([]);
              setAvaialableEmployee([]);
              setAvailableCnt(0);
              setCompletePercentage(0);
              const iterations = 0;
              setTotalOutOfTask(0);
              setCompleteCount(0);
              getCompletedTaskCount(catid, searchdate);
              setCompleteIteration(iterations);
              //setShowLoader(false);
            }
            setUpload(!upload);
          }
        })
        .catch((err) => {
          //setShowLoader(false);
        });
    } else {
      setEmployeeTaskList([]);
    }
  };

  const getEmployeeSchedule = (id) => {
    setShowLoader(true);
    //setCategoryID(id)

    //makeGetRequest("employeeschedular/employeesschedular/" + id + "/'" + startDate + "'")
    makeGetRequest(
      "employeeschedular/employeesassignednewschedular/" +
        id +
        "/'" +
        startDate +
        "'/'" +
        weekDay +
        "'/'" +
        sendcurrentTime +
        "'"
    )
      .then((response) => {
        console.log(response.data.data);
        if (response.data.data) {
          //alert(response.data.data.rows[0].categories.length);
          if (response.data.data.rows[0].categories.length > 0) {
            setSelectedValue(response.data.data.rows[0].categories);
            setCategoryList(response.data.data.rows[0].categories);
            setWeekDay(response.data.data.rows[0].weekday);
            localStorage.setItem(
              "employeetaskcatid",
              response.data.data.rows[0].categories[0].categoryid
            );
            setDropDownSelect(
              response.data.data.rows[0].categories[0].categoryid
            );

            let userIDsession = localStorage.getItem("userId");
            getEmployeeSchedulewithexisting(
              parseInt(userIDsession),
              parseInt(response.data.data.rows[0].categories[0].categoryid)
            );

            //setWeekDay("Wednesday")
            //setSelectedTaskList(response.data.data.res.rows)
            //setIncValue(response.data.data.totalCount)
            //setShowLoader(false);
          } else {
            //setSelectedTaskList()
            //setIncValue(0)
            setShowLoader(false);
          }
          setUpload(!upload);
        }
      })
      .catch((err) => {
        setShowLoader(false);
      });
  };

  async function getAllCategoryList(searchString) {
    if (!searchString) searchString = null;
    //setShowLoader(true);
    await makeGetRequest(
      "category/getcategorylist/categoryid/asc/" + searchString
    )
      .then((response) => {
        if (response.data.data.res) {
          if (response.data.data.res.length > 0) {
            setCategoryList(response.data.data.res);

            setCategoryID(response.data.data.res[0].categoryid);
            setCategoryName(response.data.data.res[0].category_name);
            setFrequancyID(response.data.data.res[0].frequancy_ids);

            const datesArray = getDatesArray(startDate, endDate);
            setMondaysCal(datesArray);
            statusChangeFun(response.data.data.res[0].categoryid);
            //setShowLoader(false);
          } else {
            setCategoryList([]);
            //setShowLoader(false);
          }
          setUpload(!upload);
        }
      })
      .catch((err) => {
        //setShowLoader(false);
      });
  }

  const [checked, setChecked] = React.useState(true);
  const handleSwitchCheck = (event) => {
    if (event.target.checked == true) {
      setChecked(event.target.checked);
      navigate("/EmployeeTaskListNew");
    } else {
      navigate("/ReportAdmin");
    }
  };

  const autoLogoutFunction = () => {
    sessionStorage.clear();
    localStorage.clear();
    navigate("/EmployeeLogin");
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(moment().format("HH:mm:ss"));
    }, 1000); // Update current time every second

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    const shiftStartMoment = moment(shiftStartTime, "HH:mm:ss");
    const shiftEndMoment = moment(shiftEndTime, "HH:mm:ss");
    const currentMoment = moment(currentTime, "HH:mm:ss");

    if (currentMoment.isAfter(shiftEndMoment)) {
      // Auto-logout functionality
      console.log("Auto-logout triggered!");
      autoLogoutFunction();
      // Clear user session, remove authentication tokens, and redirect to login page
    }
  }, [currentTime, shiftStartTime, shiftEndTime]);

  useEffect(() => {
    setUserId(localStorage.getItem("userId"));
    let id = localStorage.getItem("userId");
    getAllScheduleList();
    getEmployeeSchedule(id);
    setInterval(() => setTodaysDate({ time: new Date() }), 1000);
    setUserName(localStorage.getItem("UserName"));
    setLocalImage(localStorage.getItem("adminProfilePic"));
    setCompanyPic(localStorage.getItem("companyLogo"));
    getEmployeeDetails(id);
  }, []);

  // React.useEffect(() => {
  //   setInterval(() => setTodaysDate({ time: new Date() }), 1000);
  //   if (categoryid == null || categoryid == "" || categoryid.length == 0) {
  //     getAllCategoryList(null);
  //     setUserId(localStorage.getItem("userId"));
  //     let id = localStorage.getItem("userId");
  //   }

  // }, []);
  return (
    <>
      <TopBar />
      <div className="wrapper">
        {/* TopBar */}
        <div className="innerPageTopSecBackPatch"></div>
        <div className="innerPageTopSec">
          {/* <div className="forBackArrow">
            <AppDrawer />
            <NavigateBeforeIcon onClick={() => navigate("/")} />
          </div> */}
          <h1>
            <marquee>Employee Task List</marquee>
          </h1>

          {localStorage.getItem("userType") == "Admin" ? (
            <div className="switchAccount profile-switcher">
              <div className="acount1" onClick={() => navigate("/ReportAdmin")}>
                <AdminPanelSettingsIcon />
              </div>
              <Switch
                {...label}
                checked={checked}
                onChange={handleSwitchCheck}
              />
              <div
                className="acount1 actPro"
                onClick={() => navigate("/EmployeeTaskListNew")}
              >
                <Person2Icon />
              </div>
            </div>
          ) : null}
        </div>
        {/* TopBarEnd */}

        <div className="addAdminEmployeeMain reportAdminMain">
          <div className="personalTsProfileTpOut emploTsTop">
            <div
              className="personalTsProfileTp"
              //onClick={() => navigate("/Profile")}
            >
              {employeeDetails.profile_pic ? (
                <img src={employeeDetails.profile_pic} />
              ) : (
                <img src={avatarPic} />
              )}
            </div>
            <h2>{employeeDetails.full_name}</h2>
            <div className="picUplod">
              <CreateIcon />
              <input
                type="file"
                accept="image/png, image/gif, image/jpeg"
                onChange={(e) => uploadImage(e)}
              />
            </div>
          </div>
          <p className="errorSt">{employeeImageErrMsg}</p>
          <h1 className="dayDateTimeTx">
            {moment(todaysDate.time).format("dddd")},{" "}
            {moment(todaysDate.time).format("L")},{" "}
            {moment(todaysDate.time).format("LTS")}{" "}
          </h1>
          {categoryList.length > 0 ? (
            <div
              className="tabsMainD topPadinTab forProSwicherPatch"
              style={{ paddingTop: 215 }}
            >
              <Box sx={{ width: "100%" }}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  {categoryList ? (
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      aria-label="basic tabs example"
                      variant="scrollable"
                      scrollButtons={false}
                      className={`kkkk ${scrolling ? "scrolledTab" : ""}`}
                    >
                      {categoryList.map((catObj, i) => (
                        <Tab
                          label={catObj.category_name}
                          onClick={(e) =>
                            changeList(
                              e,
                              catObj.categoryid,
                              catObj.category_name
                            )
                          }
                        />
                      ))}
                    </Tabs>
                  ) : (
                    <></>
                  )}
                </Box>
                {categoryList.map((catObj, i) => (
                  <CustomTabPanel value={value} index={i}>
                    <div className="tabInrContDiv">
                      <div className="daySlider emplShifSlider checkListSlidMain">
                        <div className="slider-container costomArrowMain">
                          {loc.length > 0 ? (
                            <>
                              <ArrowCircleLeftIcon
                                className="slikArLeft"
                                onClick={handlePrev}
                              />
                              <Slider ref={sliderRef} {...settings2}>
                                {loc.length > 0 &&
                                  loc.map((locobj, index) => (
                                    <div>
                                      <h3 className="slidDayHeading newSLideDayHeading">
                                        <b>{locobj.location}</b>
                                      </h3>
                                      <div className="storOpeningMain">
                                        {avaialableEmployee.length > 0 ? (
                                          <Grid item xs={12} md={12}>
                                            <div class="repViw">
                                              {avaialableEmployee.map(
                                                (availableobj, index) => (
                                                  <p>
                                                    {availableobj.completed_tasks ==
                                                    0 ? (
                                                      <em className="repViwColorRed"></em>
                                                    ) : (availableobj.completed_tasks /
                                                        totalOutOfTask) *
                                                        100 <
                                                      50 ? (
                                                      <em className="repViwColorYellow"></em>
                                                    ) : (
                                                      <em className="repViwColorGreen"></em>
                                                    )}

                                                    <span>
                                                      {availableobj.full_name
                                                        .trim()
                                                        .includes(" ")
                                                        ? availableobj.full_name.split(
                                                            " "
                                                          )[0] +
                                                          " " +
                                                          availableobj.full_name.split(
                                                            " "
                                                          )[1][0]
                                                        : availableobj.full_name}{" "}
                                                      {employeeTaskList.length >
                                                      0 ? (
                                                        <b class="repViewCount">
                                                          {availableobj.completed_tasks ==
                                                          0 ? (
                                                            <>
                                                              {
                                                                availableobj.completed_tasks
                                                              }{" "}
                                                            </>
                                                          ) : (
                                                            (percentage =
                                                              Math.round(
                                                                (parseInt(
                                                                  availableobj.completed_tasks
                                                                ) /
                                                                  totalOutOfTask) *
                                                                  100
                                                              ))
                                                          )}
                                                          % (
                                                          {parseInt(
                                                            availableobj.completed_tasks
                                                          )}{" "}
                                                          / {totalOutOfTask})
                                                        </b>
                                                      ) : (
                                                        <b class="repViewCount">
                                                          0%(0 / 0)
                                                        </b>
                                                      )}
                                                    </span>
                                                  </p>
                                                )
                                              )}
                                            </div>
                                          </Grid>
                                        ) : null}

                                        <Grid item xs={12} md={12}>
                                          {completeCount > 0 ? (
                                            <Grid item xs={12} md={12}>
                                              <div className="progBarMain">
                                                <div className="progBarInr">
                                                  <section>
                                                    {completePercentage > 0 ? (
                                                      <ol class="progress-bar">
                                                        {Array.from(
                                                          { length: 10 },
                                                          (_, i) =>
                                                            completePercentage >=
                                                              10 * (i + 1) &&
                                                            completePercentage >=
                                                              10 * (i + 2) ? (
                                                              <li
                                                                key={i}
                                                                class="is-complete"
                                                              ></li>
                                                            ) : completePercentage >=
                                                                10 * (i + 1) &&
                                                              completePercentage <=
                                                                10 * (i + 2) ? (
                                                              <li
                                                                key={i}
                                                                class="is-active"
                                                              >
                                                                {
                                                                  completePercentage
                                                                }
                                                                %
                                                              </li>
                                                            ) : (
                                                              <li key={i}></li>
                                                            )
                                                        )}
                                                      </ol>
                                                    ) : null}

                                                    {/* <ol class="progress-bar">
                            <li class="is-complete">
                              <span>10%</span>
                            </li>
                            <li class="is-complete">
                              <span>20%</span>
                            </li>
                            <li class="is-complete">
                              <span>30%</span>
                            </li>
                            <li class="is-complete">
                              <span>40%</span>
                            </li>
                            <li class="is-complete">
                              <span>50%</span>
                            </li>
                            <li class="is-complete">
                              <span>60%</span>
                            </li>
                            <li class="is-active">
                              <span>70%</span>
                            </li>
                            <li>
                              <span>80%</span>
                            </li>
                            <li>
                              <span>90%</span>
                            </li>
                            <li>
                              <span>100%</span>
                            </li>
                          </ol> */}
                                                  </section>
                                                </div>
                                              </div>
                                            </Grid>
                                          ) : (
                                            <Grid item xs={12} md={12}>
                                              <div className="progBarMain">
                                                <div className="progBarInr">
                                                  <section>
                                                    <ol class="progress-bar">
                                                      <li class="is-complete is-active">
                                                        <span>0%</span>
                                                      </li>
                                                      <li>
                                                        <span>10%</span>
                                                      </li>
                                                      <li>
                                                        <span>20%</span>
                                                      </li>
                                                      <li>
                                                        <span>30%</span>
                                                      </li>
                                                      <li>
                                                        <span>40%</span>
                                                      </li>
                                                      <li>
                                                        <span>50%</span>
                                                      </li>
                                                      <li>
                                                        <span>60%</span>
                                                      </li>
                                                      <li>
                                                        <span>70%</span>
                                                      </li>
                                                      <li>
                                                        <span>80%</span>
                                                      </li>
                                                      <li>
                                                        <span>90%</span>
                                                      </li>
                                                      <li>
                                                        <span>100%</span>
                                                      </li>
                                                    </ol>
                                                  </section>
                                                </div>
                                              </div>
                                            </Grid>
                                          )}
                                        </Grid>

                                        <div
                                          className="listingSearch"
                                          style={{ marginTop: 10 }}
                                        >
                                          <input
                                            type="text"
                                            placeholder="Search"
                                            onChange={(e) =>
                                              getSerachList(e.target.value)
                                            }
                                          />
                                          <div className="sortingIcoOut">
                                            <ToggleButton
                                              value="check"
                                              selected={selected}
                                              onChange={() => {
                                                handleChangeSortBy();
                                              }}
                                            >
                                              <ImportExportIcon
                                                className="visitorFilterIco"
                                                aria-haspopup="true"
                                              />
                                            </ToggleButton>
                                          </div>
                                        </div>

                                        {employeeTaskList.length > 0 ? (
                                          <div className="employyeListingMain progessTop">
                                            {employeeTaskList.map(
                                              (takObj, index) => (
                                                <div
                                                  className={`swipe-container`}
                                                >
                                                  <div
                                                    style={{ paddingRight: 33 }}
                                                    className="emploTaskListInr"
                                                    id="listID"
                                                    onClick={forSelectList}
                                                  >
                                                    {takObj.is_multiple_person_task ? (
                                                      <GroupIcon className="forTaskIndicatUser" />
                                                    ) : (
                                                      <PersonIcon className="forTaskIndicatUser" />
                                                    )}

                                                    <div className="withCheck">
                                                      {/* {takObj.assigntask != null && takObj.assigntask.some(item => item.empid == UserId) ? (
                                                  <div>Employee ID is available</div>
                                                  ) : (
                                                  <div>Employee ID is not available</div>
                                                  )} */}

                                                      <div
                                                        onClick={
                                                          takObj.assigntask !=
                                                            null &&
                                                          takObj.assigntask.some(
                                                            (item) =>
                                                              item.empid ==
                                                              UserId
                                                          )
                                                            ? (e) =>
                                                                handleClick(
                                                                  index,
                                                                  takObj.taskid,
                                                                  UserId,
                                                                  takObj.assigntask,
                                                                  locobj.location,
                                                                  takObj.is_multiple_person_task
                                                                )
                                                            : takObj.assigntask !=
                                                                null &&
                                                              !takObj.assigntask.some(
                                                                (item) =>
                                                                  item.empid ==
                                                                  UserId
                                                              ) &&
                                                              !takObj.is_multiple_person_task
                                                            ? (e) =>
                                                                handleskip(
                                                                  e,
                                                                  takObj
                                                                    .assigntask[0]
                                                                    .full_name
                                                                )
                                                            : takObj.assigntask !=
                                                                null &&
                                                              !takObj.assigntask.some(
                                                                (item) =>
                                                                  item.empid ==
                                                                  UserId
                                                              ) &&
                                                              takObj.is_multiple_person_task
                                                            ? (e) =>
                                                                handleClick(
                                                                  index,
                                                                  takObj.taskid,
                                                                  UserId,
                                                                  "Open",
                                                                  locobj.location,
                                                                  takObj.is_multiple_person_task
                                                                )
                                                            : (e) =>
                                                                handleClick(
                                                                  index,
                                                                  takObj.taskid,
                                                                  UserId,
                                                                  "Open",
                                                                  locobj.location,
                                                                  takObj.is_multiple_person_task
                                                                )
                                                        }
                                                        id={
                                                          "listID-" +
                                                          index +
                                                          takObj.taskid +
                                                          UserId
                                                        }
                                                        className={
                                                          takObj.assigntask !=
                                                          null
                                                            ? takObj.is_multiple_person_task
                                                              ? takObj.assigntask.some(
                                                                  (item) =>
                                                                    item.empid ==
                                                                    UserId
                                                                )
                                                                ? takObj.assigntask.find(
                                                                    (item) =>
                                                                      item.empid ==
                                                                      UserId
                                                                  )
                                                                    .taskstatus ==
                                                                  "Assigned"
                                                                  ? "class2"
                                                                  : "class1"
                                                                : ""
                                                              : !takObj.is_multiple_person_task &&
                                                                takObj
                                                                  .assigntask[0]
                                                                  .taskstatus ==
                                                                  "Assigned"
                                                              ? "class2"
                                                              : "class1"
                                                            : ""
                                                        }
                                                      >
                                                        <img
                                                          src={unlock}
                                                          className="forClass1"
                                                        />
                                                        <VerifiedIcon className="forClass2" />
                                                        <LockPersonIcon className="forClass3" />
                                                      </div>
                                                      <div
                                                        style={{
                                                          textAlign: "left",
                                                        }}
                                                      >
                                                        <h1
                                                          onClick={() =>
                                                            navigate(
                                                              "/TaskDetails",
                                                              {
                                                                state: {
                                                                  Id: takObj.taskid,
                                                                },
                                                              }
                                                            )
                                                          }
                                                        >
                                                          {takObj.task_name}
                                                        </h1>
                                                        {/* <span className="inrTileTaskName">
                                                          {categoryname}
                                                        </span> */}
                                                        <div className="forMultiDayAndTim">
                                                          {takObj.assigntask !=
                                                          null ? (
                                                            takObj.assigntask.map(
                                                              (
                                                                multitaskperson,
                                                                i
                                                              ) => (
                                                                <em className="byWicTx">
                                                                  {
                                                                    multitaskperson.full_name
                                                                  }
                                                                  ,{" "}
                                                                  {moment(
                                                                    multitaskperson.created_at
                                                                  ).format(
                                                                    "LTS"
                                                                  )}
                                                                </em>
                                                              )
                                                            )
                                                          ) : (
                                                            <></>
                                                          )}
                                                        </div>
                                                      </div>
                                                    </div>

                                                    {takObj.is_beforeafter ? (
                                                      <div className="taskImUpload taskImUploadNewSt">
                                                        <CameraAltOutlinedIcon
                                                          onClick={() =>
                                                            handleClickOpen(
                                                              takObj.taskid,
                                                              UserId,
                                                              takObj
                                                            )
                                                          }
                                                        />
                                                      </div>
                                                    ) : null}
                                                  </div>
                                                </div>
                                              )
                                            )}
                                          </div>
                                        ) : (
                                          <>
                                            <div className="dataNoF dataNoFNew">
                                              <img
                                                src={noPic}
                                                style={{ maxWidth: 300 }}
                                              />
                                              <p>
                                                Tasks are not available for now.
                                              </p>
                                            </div>
                                          </>
                                        )}
                                      </div>
                                    </div>
                                  ))}
                              </Slider>
                              <ArrowCircleRightIcon
                                className="slikArRight"
                                onClick={handleNext}
                              />
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    </div>
                  </CustomTabPanel>
                ))}
              </Box>
            </div>
          ) : (
            <div className="dataNoF dataNoFNew">
              <img src={noPic} style={{ maxWidth: 300 }} />
              <p>Tasks are not available for this time.</p>
            </div>
          )}
        </div>

        {/* <div className="profileSwitchOuter"> */}
        <UserBottom />
        {/* <div className="profileSwitchMain">
            <div className="profileSwitchInr">
              <Button
                onClick={() => navigate("/EmployeeTaskListNew")}
                className="activeProSwich"
              >
                <ApartmentIcon /> Business
              </Button>
              <Button onClick={() => navigate("/PersonalTaskNew")}>
                <PermIdentityIcon /> Personal
              </Button>
            </div>
          </div> */}
        {/*  </div> */}

        {/* <FloatButton route1={"EmployeeTaskList"} /> */}
      </div>

      {/* BeforAfterImage */}
      {/* <div className="">
        <React.Fragment>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="myAppModalMain forBeforAfterMod"
          >
            <DialogTitle id="alert-dialog-title">
              <h1 className="modalHeading">Before & After Pictures</h1>
              <IconButton className="modalCloseIco" onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <div className="modalBodyCont">
                  <div className="beforAftMain">
                    <div className="beforSt">
                      <h1 className="befAftHeading">Before</h1>
                      {ImageUploadBefore ? (
                        <>
                          {ImageUploadBefore.map((img) => (
                            <div className="befAftImgDiv">
                              <img src={img} />
                            </div>
                          ))}
                        </>
                      ) : (
                        <p>No Image Found</p>
                      )}
                    </div>
                    <div className="afterSt">
                      <h1 className="befAftHeading">After</h1>
                      {ImageUploadAfter ? (
                        <>
                          {ImageUploadAfter.map((img) => (
                            <div className="befAftImgDiv">
                              <img src={img} />
                            </div>
                          ))}
                        </>
                      ) : (
                        <p>No Image Found</p>
                      )}
                    </div>
                  </div>
                </div>
              </DialogContentText>
            </DialogContent>
          </Dialog>
        </React.Fragment>
      </div> */}

      {/* BeforAfterImage */}
      <div className="">
        <React.Fragment>
          <Dialog
            open={open}
            //onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="myAppModalMain forBeforAfterMod"
          >
            <DialogTitle id="alert-dialog-title">
              <h1 className="modalHeading">Before & After Pictures</h1>
              <IconButton className="modalCloseIco" onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <div className="modalBodyCont">
                  <div className="beforAftMain">
                    <div className="beforSt">
                      <h1 className="befAftHeading">Before</h1>
                      {ImageUploadBefore.length < 4 ? (
                        <div className="befAftImgDiv newUpImMain">
                          {/* <img src={afterBef1} /> */}
                          <AddAPhotoIcon />
                          <Button
                            className="befAftUploadBt"
                            onChange={(e) => uploadImagesBefore(e)}
                          >
                            <input type="file" />
                            Upload Image
                          </Button>
                        </div>
                      ) : null}
                      {ImageUploadBefore.length > 0 ? (
                        <>
                          {ImageUploadBefore.map((imgObj, index) => (
                            <div className="befAftImgDiv">
                              {imgObj.match(
                                /.(jpg|JPG|jpeg|JPEG|png|PNG|gif|GIF)$/i
                              ) ? (
                                <Zoom>
                                  <img src={imgObj} />
                                </Zoom>
                              ) : imgObj.match(
                                  /.(mp4|MP4|3gp|3GP|flv|FLV)$/i
                                ) ? (
                                <div className="videoPlaMain">
                                  <video width="100%" height="85" controls>
                                    <source src={imgObj} type="video/mp4" />
                                  </video>
                                </div>
                              ) : null}
                              <div
                                className="imgClgView"
                                onClick={() => deleteImgBefore(index)}
                              >
                                <CloseIcon />
                              </div>
                            </div>
                          ))}
                        </>
                      ) : null}
                      <p className="errorSt">{imageBeforeErrMsg}</p>
                    </div>
                    <div className="afterSt">
                      <h1 className="befAftHeading">After</h1>
                      {ImageUploadAfter.length < 4 ? (
                        <div className="befAftImgDiv newUpImMain">
                          <AddAPhotoIcon />
                          {/* <img src={afterBef2} /> */}
                          <Button
                            className="befAftUploadBt"
                            onChange={(e) => uploadImagesAfter(e)}
                          >
                            <input type="file" />
                            Upload Image
                          </Button>
                        </div>
                      ) : null}
                      {ImageUploadAfter.length > 0 ? (
                        <>
                          {ImageUploadAfter.map((imgObj, index) => (
                            <div className="befAftImgDiv">
                              {imgObj.match(
                                /.(jpg|JPG|jpeg|JPEG|png|PNG|gif|GIF)$/i
                              ) ? (
                                <Zoom>
                                  <img src={imgObj} />
                                </Zoom>
                              ) : imgObj.match(
                                  /.(mp4|MP4|3gp|3GP|flv|FLV)$/i
                                ) ? (
                                <div className="videoPlaMain">
                                  <video width="100%" height="85" controls>
                                    <source src={imgObj} type="video/mp4" />
                                  </video>
                                </div>
                              ) : null}
                              <div
                                className="imgClgView"
                                onClick={() => deleteImgAfter(index)}
                              >
                                <CloseIcon />
                              </div>
                            </div>
                          ))}
                        </>
                      ) : null}
                      <p className="errorSt">{imageAfterErrMsg}</p>
                    </div>
                  </div>
                  <Grid item xs={12} md={12}>
                    <Button className="logginBt" onClick={updateTaskSchedular}>
                      {" "}
                      Submit
                    </Button>
                  </Grid>
                  {showLoader ? (
                    <div className="loaderNew">
                      <div className="loader"></div>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </DialogContentText>
            </DialogContent>
          </Dialog>
        </React.Fragment>
      </div>

      {showLoader ? (
        <div className="loaderNew">
          <div className="loader"></div>
        </div>
      ) : (
        <></>
      )}

      {showLoaderImage ? (
        <div className="videoLoaderMain">
          <img src={videoLoadImg} />
          <h1>
            Please Hold on,
            <br /> Uploading your Video or Image...
          </h1>
        </div>
      ) : (
        <></>
      )}
    </>
  );

  // function NextArrow(props) {
  //   const datesArrowArrayVal = getArrowDatesArray(startDate, endDate);
  //   const selectedDate = datesArrowArrayVal[props.currentSlide];
  //   console.log("Selected date:", selectedDate);
  //   if (startADate != selectedDate) {
  //     setStartADate(selectedDate);
  //     const date = moment(selectedDate);
  //     const dayName = date.format("dddd");
  //     setWeekDay(dayName);
  //     ChangeDateFun(categoryid, selectedDate, dayName);
  //   }
  //   return (
  //     <button class="slick-arrow slick-next" onClick={props.onClick}>
  //       {" "}
  //       Next
  //     </button>
  //   );

  //   return false;
  // }
}

export default EmployeeTaskListNew;
