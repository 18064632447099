import React from "react";
import { useNavigate } from "react-router-dom";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";

function PrivacyPolicy() {
  const navigate = useNavigate();
  return (
    <>
      <div className="wrapper innerPagesMain">
        {/* TopBar */}
        <div className="innerPageTopSecBackPatch"></div>
        <div className="innerPageTopSec">
          <div className="forBackArrow">
            <NavigateBeforeIcon onClick={() => navigate(-1)} />
          </div>
          <h1>Privacy Policy</h1>
          <div className="forBackArrow"></div>
        </div>
        {/* TopBarEnd */}

        <div className="policyContentMain">
          <div className="policyInrCont">
            <p className="moreContTx">Last updated: 1/17/2025</p>
            <p className="moreContTx">
              Thank you for using ToDoBoom! Your privacy is important to us.
              This Privacy Policy explains how we collect, use, and protect your
              information when you use our mobile app.
            </p>
          </div>

          <div className="policyInrCont">
            <h2 className="moreHeadiMain">Information we collect</h2>
            <p className="moreContTx">
              We may collect the following types of information:
            </p>
            <ul>
              <li>
                Personal Information: Name, email address, and other details you
                provide during signup
              </li>
              <li>
                Task Data: Details of tasks, schedules, and associated
                activities.
              </li>
              <li>
                Device Information: IP address, operating system, and app usage
                data for analytics and performance improvement.
              </li>
            </ul>
          </div>

          <div className="policyInrCont">
            <h2 className="moreHeadiMain">How We Use Your Information</h2>
            <p className="moreContTx">We use the collected information to:</p>
            <ul>
              <li>Manage your tasks and schedules effectively.</li>
              <li>Provide app features and improvements.</li>
              <li>Ensure data security and prevent fraud.</li>
              <li>Communicate with you for updates or support.</li>
            </ul>
          </div>

          <div className="policyInrCont">
            <h2 className="moreHeadiMain">Data Sharing and Security</h2>
            <ul>
              <li>
                We do not sell or share your personal information with third
                parties without your consent.
              </li>
              <li>
                Data is encrypted and stored securely to protect it from
                unauthorized access.
              </li>
              <li>
                Third-party services we use (e.g., analytics or cloud storage)
                comply with data privacy regulations.
              </li>
            </ul>
          </div>

          <div className="policyInrCont">
            <h2 className="moreHeadiMain">User Rights</h2>
            <p className="moreContTx">You have the right to:</p>
            <ul>
              <li>Access or update your personal information.</li>
              <li>Delete your account and associated data.</li>
              <li>
                Opt-out of certain data collection features (e.g., analytics).
              </li>
            </ul>
          </div>

          {/* <div className="policyInrCont">
            <h2 className="moreHeadiMain">Cookies and Tracking</h2>
            <p className="moreContTx">
              ToDoBoom may use cookies or similar tracking technologies to
              enhance your app experience.
            </p>
          </div> */}

          <div className="policyInrCont">
            <h2 className="moreHeadiMain">Changes to This Policy</h2>
            <p className="moreContTx">
              We may update this Privacy Policy from time to time. Changes will
              be notified via app updates or email.
            </p>
          </div>

          <div className="policyInrCont">
            <h2 className="moreHeadiMain">Contact Us</h2>
            <p className="moreContTx">
              If you have any questions or concerns about this Privacy Policy,
              please contact us.
            </p>
            {/* <ul>
              <li>Email:</li>
            </ul> */}
          </div>

          <div className="policyInrCont">
            <h2 className="moreHeadiMain">
              By using ToDoBoom, you agree to this Privacy Policy.
            </h2>
          </div>
        </div>
      </div>
    </>
  );
}

export default PrivacyPolicy;
