import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Grid, Box } from "@mui/material";
import "react-medium-image-zoom/dist/styles.css";
import AdminBottomNav from "../AdminBottomNav/AdminBottomNav";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import Checkbox from "@mui/material/Checkbox";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import profilePic from "../../../assets/images/Profile-Picture.jpg";

import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../../FormikControl";

import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import swal from "sweetalert";
import {
  makeGetRequest,
  makeNewGetRequest,
  makePostRequest,
} from "../../../utils/utils";

function AddEmployeeAdmin() {
  const navigate = useNavigate();
  const [showLoader, setShowLoader] = React.useState(false);
  const [loginErrMsg, setLoginErrMsg] = React.useState("");
  const [formValues, setFormValues] = React.useState(null);
  const [TempUser, setTempUser] = useState([]);
  const [isActive, setIsActive] = useState(true);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isAgeGreter16Yrs, setIsAgeGreter16Yrs] = useState(false);
  const [fullname, setFullname] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [emailid, setEmailid] = React.useState("");
  const [contactno, setContactno] = React.useState("");
  const [employeeCode, setEmployeeCode] = React.useState(0);
  const [reliabilityScore, setReliabilityScore] = useState("0.00");
  const [deviceUserType, setDeviceUserType] = useState("activeUser");

  function noWhitespace() {
    return this.transform((value, originalValue) => (/\s/.test(originalValue) ? NaN : value));
  }

  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

  const initialValues = {
    employee_code: "",
    full_name: "",
    password: "",
    email_id: "",
    contact_no: "",
    reliability_score: "",
    device_user_type: "",
  };

  const validationSchema = Yup.object({
    employee_code: Yup.string()
      .trim("Space is not Allow")
      .strict("Space is not Allow")
      .max(8, "8 digit employee code")
      .required("Required"),
    full_name: Yup.string()
      .trim("Space is not Allow")
      .strict("Space is not Allow")
      .required("Required"),
    password: Yup.string()
      .min(4, "4 digit password")
      .max(4, "4 digit password")
      .trim("Space is not Allow")
      .strict("Space is not Allow")
      .typeError("Invalid Number").required("Required"),
    email_id: Yup.string().email(),
    contact_no: Yup.string()
      .min(10, "10 digit mobile no")
      .max(10, "10 digit mobile no")
      .trim("Space is not Allow")
      .strict("Space is not Allow")
      .matches(phoneRegExp, 'Phone number is not valid')
      .typeError("Invalid Number")
      .required("Required"),
    reliability_score: Yup.string().required("Required"),
    device_user_type: Yup.string().required("Required"),
  });

  const onSubmit = async (values) => {
    console.log("Form Data", values);
    console.log(checkedLocations.join(", "));

    setShowLoader(true);
    setLoginErrMsg("");
    values.status = isActive;
    values.is_deleted = false;
    values.user_type = "User";
    values.is_admin = isAdmin;
    values.is_agegreter16yrs=isAgeGreter16Yrs;
    values.locations = checkedLocations.join(", ");
    if (isAdmin) values.user_type = "Admin";
    else values.user_type = "User";
    values.schemaname = sessionStorage.getItem("schema");
    let ComId = localStorage.getItem("companyId");
    values.company_id = parseInt(ComId);
    console.log("Form Data", values);
    await makePostRequest("employee/insertemployee", values)
      .then((response) => {
        if (response.data) {
          console.log(response);
          setLoginErrMsg("");
          setShowLoader(false);
          setTempUser(response.data.data);
          navigate("/EmployeeListing");
        }
      })
      .catch((err) => {
        swal("Warning", err.response.data.message, "warning", {
          timer: 3000,
          buttons: false,
        });
        setShowLoader(false);
      });
  };

  const checkAdminStatus = (status) => {
    console.log("StatusAdmin", status);
    setIsAdmin(status);
  };

  const checkisAgeGreter16Yrs = (status) => {
    console.log("status age", status);
    setIsAgeGreter16Yrs(status);
  };

  const checkActiveStatus = (status) => {
    console.log("StatusActive", status);
    setIsActive(status);
  };

  const handleKeyPress = (e) => {
    if (e.target.value != "") {
      setContactno(e.target.value);
      let lastFourDigits = e.target.value.substr(-4);
      setPassword(lastFourDigits);
      const savedValues = {
        full_name: fullname,
        password: lastFourDigits,
        employee_code: employeeCode,
        email_id: emailid,
        contact_no: e.target.value,
        is_admin: isAdmin,
        is_agegreter16yrs:isAgeGreter16Yrs,
        reliability_score: reliabilityScore,
        device_user_type: deviceUserType,
      };
      setFormValues(savedValues);
    }
  };

  const handleEmail = (e) => {
    if (e.target.value != "") {
      setEmailid(e.target.value);
    }
  };

  const handleFname = (e) => {
    if (e.target.value != "") {
      setFullname(e.target.value);
    }
  };

  const handleEmployeeCode = (e) => {
    if (e != "") {
      setEmployeeCode(e);
    }
  };

  const handleRelibility = (e) => {
    if (e != "") {
      setReliabilityScore(e);
    }
  };

  const [checkedLocations, setCheckedLocations] = useState([]);

  const handleChangeCheckbox = (e) => {
    const locName = e.target.value;

    setCheckedLocations((prev) => {
      if (e.target.checked) {
        // Add to the list if checked
        return [...prev, locName];
      } else {
        // Remove from the list if unchecked
        return prev.filter((name) => name !== locName);
      }
    });
  };

  const handleDeviceUserType = (e) => {
    if (e != "") {
      setDeviceUserType(e);
    }
  };

  async function getUserCode() {
    await makeNewGetRequest(
      "employee/getlastemployee/" +
        localStorage.getItem("companyId") +
        "/" +
        sessionStorage.getItem("schema")
    )
      .then((response) => {
        if (response.data.data) {
          console.log(response.data.data.rows[0].employee_id);
          let newUserCode =
            parseInt(response.data.data.rows[0].employee_id) + 1;
          const newEmployeeCode = `${String(newUserCode).padStart(5, "0")}`;
          setEmployeeCode(newEmployeeCode);
          const savedValues = {
            full_name: fullname,
            password: password,
            employee_code: newEmployeeCode,
            email_id: emailid,
            contact_no: contactno,
            is_admin: isAdmin,
            is_agegreter16yrs:isAgeGreter16Yrs,
            reliability_score: reliabilityScore,
            device_user_type: deviceUserType,
          };
          setFormValues(savedValues);
        }
      })
      .catch((err) => {});
  }

  const [locations, setLocations] = useState([]);
  async function getLocations() {
    await makeNewGetRequest(
      "location/getlocationlist/" + localStorage.getItem("companyId")+"/null"
    )
      .then((response) => {
        if (response.data.data) {
          console.log(response.data.data.res);
          setLocations(response.data.data.res);
        } else {
          setLocations([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    getUserCode();
    getLocations();
  }, []);

  return (
    <>
      <div className="wrapper innerPagesMain">
        {/* TopBar */}
        <div className="innerPageTopSecBackPatch"></div>
        <div className="innerPageTopSec">
          <div className="forBackArrow">
            <NavigateBeforeIcon onClick={() => navigate(-1)} />
          </div>
          <h1>Add Employee</h1>
          <div className="forBackArrow"></div>
        </div>
        {/* TopBarEnd */}

        <div className="addAdminEmployeeMain">
          <Formik
            initialValues={formValues || initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            enableReinitialize
          >
            {(formik) => (
              <Form>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12}>
                    <FormikControl
                      control="input"
                      type="text"
                      label="Employee Id"
                      placeholder="Employee Id"
                      labelClass="myLable"
                      value={employeeCode}
                      name="employee_code"
                      onChange={(e) => handleEmployeeCode(e.target.value)}
                      className="myInput"
                      onBlur={(e) => handleEmployeeCode(e.target.value)}
                      isRequired="true"
                    />
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <FormikControl
                      control="input"
                      type="text"
                      label="Employee Name"
                      placeholder="Employee Name"
                      labelClass="myLable"
                      name="full_name"
                      className="myInput"
                      onBlur={(e) => handleFname(e)}
                      isRequired="true"
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormikControl
                      control="input"
                      type="text"
                      label="Phone Number"
                      placeholder="Phone Number"
                      labelClass="myLable"
                      name="contact_no"
                      className="myInput"
                      onBlur={(e) => handleKeyPress(e)}
                      isRequired="true"
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormikControl
                      control="input"
                      type="text"
                      label="Employee Pin"
                      placeholder="Pin"
                      labelClass="myLable"
                      name="password"
                      className="myInput"
                      onBlur={(e) => setPassword(e.target.value)}
                      isRequired="true"
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormikControl
                      control="input"
                      type="text"
                      label="Email"
                      placeholder="Email"
                      labelClass="myLable"
                      name="email_id"
                      className="myInput"
                      onBlur={(e) => handleEmail(e)}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormikControl
                      control="select"
                      label="Select Device User Type"
                      name="device_user_type" // Formik field name
                      options={[
                        { key: "Select an option", value: "" },
                        { key: "Active User", value: "activeUser" },
                        { key: "Test User", value: "testUser" },
                      ]} // Array of options
                      labelClass="myLable"
                      className="myInput"
                      onBlur={(e) => handleDeviceUserType(e)}
                      isRequired="true"
                    />
                  </Grid>
                  {/* code by yamini*/}
                  <Grid item xs={12} md={12}>
                    <FormikControl
                      control="select" // This specifies it is a dropdown/select control
                      label="Select Relibility Score"
                      name="reliability_score" // Formik field name
                      options={[
                        { key: "Select an option", value: "" },
                        { key: "0.00", value: "0.00" },
                        { key: "0.10", value: "0.10" },
                        { key: "0.20", value: "0.20" },
                        { key: "0.30", value: "0.30" },
                        { key: "0.40", value: "0.40" },
                        { key: "0.50", value: "0.50" },
                        { key: "0.60", value: "0.60" },
                        { key: "0.70", value: "0.70" },
                        { key: "0.80", value: "0.80" },
                        { key: "0.90", value: "0.90" },
                        { key: "1.00", value: "1.00" },
                      ]} // Array of options
                      labelClass="myLable"
                      className="myInput"
                      onBlur={(e) => handleRelibility(e)}
                      isRequired="true"
                    />
                  </Grid>
                  <Grid item xs={12} md={12} className="alignCheckBx">
                    {locations.map((loc, index) => (
                      <FormControlLabel
                        name="frequancy_ids"
                        control={
                          <Checkbox
                            id={loc.loc_id}
                            value={loc.loc_name}
                            name={loc.loc_name}
                            checked={checkedLocations.includes(loc.loc_name)}
                            onChange={(e) => handleChangeCheckbox(e)}
                          />
                        }
                        label={loc.loc_name}
                      />
                    ))}
                  </Grid>
                  {/* {locations.length > 0 ? (
                      locations.map((loc, index) => (
                        <Grid item xs={12} md={12} key={loc.loc_id || index}>
                          <FormikControl
                            control="checkbox"
                            name={loc.loc_name}
                            value={loc.loc_name}
                          // onChange={(e) => handleChangeCheckbox(e)}
                          />
                        </Grid>
                      ))
                    ) : (
                      <div>No locations available</div>
                    )} */}
                  <Grid item xs={12} md={12}>
                    <div className="mySwitch">
                      <FormControlLabel
                        control={
                          <Switch
                            checked={isActive}
                            onChange={(e) =>
                              checkActiveStatus(e.target.checked)
                            }
                          />
                        }
                        label="Active"
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <div className="mySwitch">
                      <FormControlLabel
                        control={
                          <Switch
                            checked={isAdmin}
                            onChange={(e) => checkAdminStatus(e.target.checked)}
                          />
                        }
                        label="Is this Admin User?"
                      />
                    </div>
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <div className="mySwitch">
                      <FormControlLabel
                        control={
                          <Switch
                            checked={isAgeGreter16Yrs}
                            onChange={(e) => checkisAgeGreter16Yrs(e.target.checked)}
                          />
                        }
                        label="Age more than 16yrs?"
                      />
                    </div>
                  </Grid>

                  <Grid item xs={12} md={12} className="stickyBtBottom">
                    <Button type="submit" className="logginBt">
                      Add Employee
                    </Button>
                  </Grid>
                  {/*  <Grid item xs={12} md={12}>
                    <h1 className="bodyHeadignAdmin">Recently Added</h1>
                  </Grid> */}
                  {/* {TempUser.length > 0 ?
                    <>
                    {TempUser.map((tempUserObj, index) => {
                    <Grid item xs={12} md={12}>
                    <div className="employyeListingMain">
                      <div className="employyeListingInr">
                        <div className="employyeLisPic">
                          <Zoom>
                            <img src={profilePic} />
                          </Zoom>
                        </div>
                        <div className="employyeLisCont">
                          <h1>{tempUserObj.full_name}</h1>
                          <span>66904</span>
                        </div>
                        <div className="employyeLisContRight">
                          <span>{tempUserObj.contact_no}</span>
                          <p>{tempUserObj.email_id}</p>
                        </div>
                      </div>
                    </div>
                    </Grid>
                    })}
                    </> :
                    <></>} */}
                </Grid>
              </Form>
            )}
          </Formik>
        </div>

        <AdminBottomNav />
      </div>
      {showLoader ? (
        <div className="loaderNew">
          <div className="loader"></div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}

export default AddEmployeeAdmin;
